import React, { useContext } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import { tampCreateContext } from '@/utils/context';
import './CorporateCulture.scss';


export default function CorporateCulture(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;

    return (
        <div className="CorporateCulture">
            <HeadMenu Background={1} />

            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                    // setindex(destination.index);
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            {/* <div className={`content1 section ${!global.isPc && 'md_content1'}`}>
                                <div className="backgroundView">
                                    <div className="backgroundViewTitle">企业文化</div>
                                    <div className="backgroundViewLine"></div>
                                    <div className="backgroundViewCon">尊重员工价值 共享发展成果</div>
                                </div>
                            </div> */}
                            <div className="content2 section">
                                <div className={`content2View`}>
                                    <div className="cultureView">
                                        <ul>
                                            <li className="cultureList">
                                                <div className="cultureListPic cultureListPic1">{locales.CorporateCulture1}</div>
                                            </li>
                                            <li className="cultureList">
                                                <div className="cultureListPic cultureListPic2">{locales.CorporateCulture2}</div>
                                            </li>
                                            <li className="cultureList">
                                                <div className="cultureListPic cultureListPic3"></div>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li className="cultureList">
                                                <div className="cultureListPic cultureListPic4"></div>
                                            </li>
                                            <li className="cultureList">
                                                <div className="cultureListPic cultureListPic5">{locales.CorporateCulture3}</div>
                                            </li>
                                            <li className="cultureList">
                                                <div className="cultureListPic cultureListPic6">{locales.CorporateCulture4}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="activity section">
                                <div className={`activityView ${!global.isPc && 'md_activityView'}`}>
                                    <div className="title">{locales.CorporateCulture5}</div>
                                    <div className="text">{locales.CorporateCulture6}</div>
                                    <ul>
                                        <li>
                                            <div className="welfarsPic"></div>
                                            <div className="welfarsTitle">{locales.CorporateCulture7}</div>
                                            <div className="welfarsText">{locales.CorporateCulture8}</div>
                                        </li>
                                        <li>
                                            <div className="welfarsPic"></div>
                                            <div className="welfarsTitle">{locales.CorporateCulture9}</div>
                                            <div className="welfarsText">{locales.CorporateCulture10}</div>
                                        </li>
                                        <li>
                                            <div className="welfarsPic"></div>
                                            <div className="welfarsTitle">{locales.CorporateCulture11}</div>
                                            <div className="welfarsText">{locales.CorporateCulture12}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <Footer section />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}