import React from 'react';
import { Typography, Divider } from 'antd';

const { Title, Paragraph, Text, Link } = Typography;

const Privacy = () => {
  return (
    <div style={{ padding: 10 }}>
      <div style={{ textAlign: 'center' }}>
        <Title>用户服务协议及隐私保护政策 </Title>
      </div>
      <Typography>
        <Paragraph>
          程创（北京）科技有限公司（以下简称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。

          请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私保护政策》。
        </Paragraph>
        <Paragraph>
          1.我们如何收集和使用您的个人信息

          个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。

          我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：
        </Paragraph>
        <Paragraph>
          1.1 您在使用我们服务时主动提供的信息

          1.1.1 注册成为用户

          为完成创建账号，您需提供以下信息：您的手机号、邮箱以及创建的用户名和密码。

          在注册过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：工作职位、公司、教育背景等。但如果您不提供这些信息，将不会影响使用本服务的基本功能。

          您提供的上述信息，将在您使用本服务期间持续授权我们使用。上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会单独征得您的授权同意。

          1.1.2 商品展示、个性化推荐、发送促销营销信息
        </Paragraph>
        <Paragraph>
          1.2开展内部数据分析和研究，第三方SDK统计服务，改善我们的产品或服务

          我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们收集的数据可能包括SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、iOS广告标识符（IDFA)、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。
        </Paragraph>
        <Paragraph>

          1.3 其他相关信息

          当我们要将信息用于本策略未载明的其它用途时，我们要将基于特定目的收集而来的信息用于其他。
        </Paragraph>
        <Paragraph>
          2. 我们如何使用 Cookie 和同类技术

          2.1 Cookie

          为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储您的偏好或购物篮内的商品等数据。

          我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。
        </Paragraph>
        <Paragraph>
          2.2 网站信标和像素标签

          除Cookie外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        </Paragraph>
        <Paragraph>
          2.3 Do Not Track（请勿追踪）

          很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track.那么我们的所有网站都会尊重您的选择。
        </Paragraph>
        <Paragraph>
          3. 您的个人信息如何在全球范围转移

          原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。

          由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。

          此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
        </Paragraph>
        <Paragraph>

          4. 我们如何共享、转让、公开披露您的个人信息

          4.1 共享

          我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：

        </Paragraph>
        <Paragraph>

          4.1.1 在获得您的明确同意后，我们会与其他方共享您的个人信息。

          4.1.2 我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。

          4.1.3 我们可能会获得您在使用第三方合作伙伴服务时所产生或分享的信息。


        </Paragraph>
        <Paragraph>

          4.1.4仅为实现本隐私保护政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，我们聘请来提供第三方数据统计和分析服务的公司可能需要采集和访问个人数据以进行数据统计和分析。在这种情况下，这些公司必须遵守我们的数据隐私和安全要求。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。

        </Paragraph>
        <Paragraph>
          4.2 转让

          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：

          4.2.1 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；

          4.2.2 在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </Paragraph>
        <Paragraph>
          4.3 公开披露

          我们仅会在以下情况下，公开披露您的个人信息：

          4.3.1 获得您明确同意后；

          4.3.2 在法律、法律程序、诉讼或政府主管部部门强制性要求的情况下，我们可能会披露您的个人消息。
        </Paragraph>
        <Paragraph>
          5. 本隐私保护政策确认、更新

          我们可能适时会对本隐私保护政策进行调整或变更，本隐私保护政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您在隐私保护政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的隐私保护政策并受其约束。一旦使用，我们默认本用户接受此须知。         </Paragraph>

      </Typography>
    </div>

  )
}

export default Privacy;