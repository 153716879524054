import React, { useState } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper-bundle.min.css';
import './News.scss';

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

export default function News(props) {
    const [index, setindex] = useState(0);

    return (
        <div className="News">
            <HeadMenu Background={index !== 0} />

            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                    setindex(destination.index);
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className="content content1 section">
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={3}
                                    pagination={{ clickable: true }}
                                >
                                    <div className="swiper-pagination"></div>
                                    <SwiperSlide></SwiperSlide>
                                    <SwiperSlide></SwiperSlide>
                                    <SwiperSlide></SwiperSlide>
                                </Swiper>
                            </div>
                            <div className="content content2 section">
                                <div className="content2View" style={!global.isPc ? { height: '100vh' } : {}}>
                                    <div className="contentTitle">一量动态</div>
                                    {
                                        global.isPc ?
                                            <div className="dynamicView">
                                                <ul>
                                                    <li>
                                                        <div className="dynamiPic"></div>
                                                        <div className="dynamiTime">2020年11月6日</div>
                                                        <div className="dynamiTitle">凝心聚力，共创建2021！</div>
                                                    </li>
                                                    <li>
                                                        <div className="dynamiPic"></div>
                                                        <div className="dynamiTime">2020年11月6日</div>
                                                        <div className="dynamiTitle">一量科技荣获“创新在宁波”电梯挑战赛第二名</div>
                                                    </li>
                                                    <li>
                                                        <div className="dynamiPic"></div>
                                                        <div className="dynamiTime">2020年11月6日</div>
                                                        <div className="dynamiTitle">一量科技荣获“创新在宁波”电梯挑战赛第二名</div>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <div className="dynamiPic"></div>
                                                        <div className="dynamiTime">2020年11月6日</div>
                                                        <div className="dynamiTitle">凝心聚力，共创建2021！</div>
                                                    </li>
                                                    <li>
                                                        <div className="dynamiPic"></div>
                                                        <div className="dynamiTime">2020年11月6日</div>
                                                        <div className="dynamiTitle">一量科技荣获“创新在宁波”电梯挑战赛第二名</div>
                                                    </li>
                                                    <li>
                                                        <div className="dynamiPic"></div>
                                                        <div className="dynamiTime">2020年11月6日</div>
                                                        <div className="dynamiTitle">一量科技荣获“创新在宁波”电梯挑战赛第二名</div>
                                                    </li>
                                                </ul>
                                            </div>
                                            :
                                            <Swiper
                                                spaceBetween={0}
                                                slidesPerView={1.2}
                                                pagination={{ clickable: true }}
                                            >
                                                <div className="swiper-pagination"></div>
                                                <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <div className="md_dynamiPic md_dynamiPic1"></div>
                                                        <div className="md_dynamiTime">2020年11月6日</div>
                                                        <div className="md_dynamiTitle">一量科技荣获“创新在宁波”电梯挑战赛第二名</div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <div className="md_dynamiPic md_dynamiPic2"></div>
                                                        <div className="md_dynamiTime">2020年11月6日</div>
                                                        <div className="md_dynamiTitle">凝心聚力，共创建2021！</div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <div className="md_dynamiPic md_dynamiPic3"></div>
                                                        <div className="md_dynamiTime">2020年11月6日</div>
                                                        <div className="md_dynamiTitle">凝心聚力，共创建2021！</div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <div className="md_dynamiPic md_dynamiPic4"></div>
                                                        <div className="md_dynamiTime">2020年11月6日</div>
                                                        <div className="md_dynamiTitle">凝心聚力，共创建2021！</div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <div className="md_dynamiPic md_dynamiPic5"></div>
                                                        <div className="md_dynamiTime">2020年11月6日</div>
                                                        <div className="md_dynamiTitle">凝心聚力，共创建2021！</div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <div className="md_dynamiPic md_dynamiPic6"></div>
                                                        <div className="md_dynamiTime">2020年11月6日</div>
                                                        <div className="md_dynamiTitle">凝心聚力，共创建2021！</div>
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>
                                    }
                                </div>
                                
                            </div>
                            <Footer section />

                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}