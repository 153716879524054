import React, { useContext } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import { tampCreateContext } from '@/utils/context';
import './TAMP.scss';


export default function TAMP(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;

    return (
        <div className="TAMP">
            <HeadMenu Background={1} />
            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                // scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                    // setindex(destination.index);
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            {/* <div className={`content content1 section`}>
                                <div className={`content1View ${!global.isPc && 'md_content1'}`}>
                                    <div className={`backgroundView`}>
                                        <div className="backgroundViewTitle">TAMP平台服务</div>
                                        <div className="backgroundViewLine"></div>
                                        <div className="backgroundViewCon">致力于服务投资顾问及其客户</div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="content content2 section">
                                <div className="content2View">

                                    <div className={`titleView ${!global.isPc && 'md_titleView'}`}>
                                        <div className="titlePic"></div>
                                        <div className="title">
                                            {locales.TAMP1}
                                        </div>
                                        <div className="titleConrent">
                                            {locales.TAMP2}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tampAdvantages section `} >
                                <div className={`tampAdvantagesView ${!global.isPc && 'md_tampAdvantages'}`}>
                                    <div className="tampAdvantagesTitle">{locales.TAMP3}</div>
                                    <ul>
                                        <li>
                                            <div className="AdvantagesPic"></div>
                                            <div className="AdvantagesTitle">{locales.TAMP4}</div>
                                            <div className="AdvantagesConrent">
                                                {locales.TAMP5}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="AdvantagesPic"></div>
                                            <div className="AdvantagesTitle">{locales.TAMP6}</div>
                                            <div className="AdvantagesConrent">
                                                {locales.TAMP7}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="AdvantagesPic"></div>
                                            <div className="AdvantagesTitle">{locales.TAMP8}</div>
                                            <div className="AdvantagesConrent">
                                                {locales.TAMP9}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tampInfosView section">
                                <div className={`tampInfos ${!global.isPc && 'md_tampInfos1'}`}>
                                    <div className="tampInfosViewText">
                                        <div className="tampInfosViewTextBox">
                                            <div className="tampInfosBoxTiele">{locales.TAMP10}</div>
                                            <div className="tampInfosBoxText">
                                                {locales.TAMP11}
                                            </div>
                                            <div className="tampInfosBoxText">
                                                {locales.TAMP12}
                                            </div>
                                            <div className="tampInfosBoxText">
                                                {locales.TAMP13}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tampInfosViePic"></div>
                                </div>
                            </div>
                            <div className="tampInfosView section">
                                <div className={`tampInfos ${!global.isPc && 'md_tampInfos2'}`}>
                                    <div className="tampInfosViePic tampInfosViePic2"></div>
                                    <div className="tampInfosViewText">
                                        <div className="tampInfosViewTextBox">
                                            <div className="tampInfosBoxTiele">{locales.TAMP14}</div>
                                            <div className="tampInfosBoxText">
                                                {locales.TAMP15}
                                            </div>
                                            <div className="tampInfosBoxText">
                                                {locales.TAMP16}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tampInfosView section">
                                <div className={`tampInfos ${!global.isPc && 'md_tampInfos3'}`}>
                                    <div className="tampInfosViewText">
                                        <div className="tampInfosViewTextBox">
                                            <div className="tampInfosBoxTiele">{locales.TAMP17}</div>
                                            <div className="tampInfosBoxText">
                                                {locales.TAMP18}
                                            </div>
                                            <div className="tampInfosBoxText">
                                                {locales.TAMP19}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tampInfosViePic tampInfosViePic3"></div>
                                </div>
                            </div>
                            <Footer section />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}