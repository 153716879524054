import React, { useRef, useState, useContext } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import { tampCreateContext } from '@/utils/context';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Banner2 from './Banner2';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper-bundle.min.css';
import './Home.scss';

SwiperCore.use([Pagination]);

export default function Home(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;
    const currentRef = useRef();
    const [index, setindex] = useState(0);
    currentRef.current = 0;

    function _CompanyNewsPush() {
        props.history.push('/News/CompanyNews');
    }

    return (
        <div className="Home">
            <HeadMenu Background={index !== 0} />
            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                onLeave={function (origin, destination, direction) {
                    setindex(destination.index);
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className={`content content1 section`}>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    // navigation
                                    pagination={{ clickable: true }}
                                    className={`${!global.isPc && 'md_content1'}`}
                                // scrollbar={{ draggable: true }}
                                // onSlideChange={() => console.log('slide change')}
                                // onSwiper={(swiper) => console.log(swiper)}
                                >
                                    <div className="swiper-pagination"></div>
                                    <SwiperSlide>
                                        <Banner2 />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="banner1">
                                            {/* <div className="bannerBack"></div> */}
                                            {/* <p>{locales.Home1}</p>
                                            <p>{locales.Home2}</p> */}
                                            <br /><br />
                                            <br /><br />
                                            <br /><br />
                                            <br /><br />
                                            <br />
                                            <br />
                                            <p>
                                                <a href="https://academy.cfirstquant.com" target="_Blank" rel="noreferrer">
                                                    <span style={{ fontSize: 50, color: '#FC7A02', fontWeight: 800, position: 'absolute', top: '60%', right: '25%' }}>点击详情</span>
                                                </a>
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className="content content2 section">
                                <div className="contentTitle">
                                    {locales.Home3}
                                    <div className="contentTitlebefore">{locales.Home4}</div>
                                </div>
                                <div className={`content2View ${!global.isPc && 'md_content2'}`}>
                                    <div className="content2ViewL">
                                        <p>一量科技荣获"创新在宁波"</p>
                                        <p>电梯挑战赛第二名</p>
                                        <p>2020/09/24</p>
                                        <div className="content2ViewLUnd">
                                            <a href="https://mp.weixin.qq.com/s/RY308vJ8mV2BfJzboOkv-Q" target="_Blank" rel="noreferrer">
                                                {locales.Home5}
                                            </a>
                                            <i></i>
                                        </div>
                                    </div>
                                    <div className="content2ViewC">
                                    </div>
                                    <div className="content2ViewR">
                                        <div className="content2ViewRView">
                                            <p>凝心聚力，共创建2021！</p>
                                            {/* <p>我们在12月18日这一天开展了以“凝心聚力，共创2021”为主题...</p> */}
                                            <div className="content2ViewRViewB">
                                                <span>2020/12/18</span>
                                                <span>
                                                    <div onClick={_CompanyNewsPush}>
                                                        {locales.Home6}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="content2ViewRView">
                                            <p>“一量”新科技，创新来演绎。</p>
                                            <div className="content2ViewRViewB">
                                                <span>2020/08/21</span>
                                                <span>
                                                    <div onClick={_CompanyNewsPush}>
                                                        {locales.Home6}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content content3 section">
                                <div className="contentTitleB">
                                    {locales.Home7}
                                </div>
                                <div className="background"></div>
                                <div className="content3Con">
                                    {
                                        global.isPc ?
                                            <>
                                                <div className="content3ConView">
                                                    <div className="content3ConPic"></div>
                                                    <div className="content3ConTitle">{locales.Home8}</div>
                                                    <div className="content3ConText">{locales.Home9}</div>
                                                </div>
                                                <div className="content3ConView">
                                                    <div className="content3ConPic"></div>
                                                    <div className="content3ConTitle">{locales.Home10}</div>
                                                    <div className="content3ConText">{locales.Home11}</div>
                                                </div>
                                                <div className="content3ConView">
                                                    <div className="content3ConPic"></div>
                                                    <div className="content3ConTitle">{locales.Home12}</div>
                                                    <div className="content3ConText">{locales.Home13}</div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <Swiper
                                                    spaceBetween={0}
                                                    slidesPerView={1.1}
                                                    pagination={{ clickable: true }}
                                                    className="swiperView"
                                                >
                                                    <div className="swiper-pagination"></div>
                                                    <SwiperSlide>
                                                        <div className="content3ConView md_content3ConView">
                                                            <div className="content3ConPic md_content3ConPic1"></div>
                                                            <div className="content3ConTitle">{locales.Home8}</div>
                                                            <div className="content3ConText">{locales.Home9}</div>
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="content3ConView md_content3ConView">
                                                            <div className="content3ConPic md_content3ConPic2"></div>
                                                            <div className="content3ConTitle">{locales.Home10}</div>
                                                            <div className="content3ConText">{locales.Home11}</div>
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="content3ConView md_content3ConView">
                                                            <div className="content3ConPic md_content3ConPic3"></div>
                                                            <div className="content3ConTitle">{locales.Home12}</div>
                                                            <div className="content3ConText">{locales.Home13}</div>
                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="content content4 section">
                                <div className="contentTitleB">
                                    {locales.Home14}
                                </div>
                                <div className="background"></div>
                                <div className="content4View">
                                    <ul>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                    <ul>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                            </div>
                            <Footer section />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}