import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { routeConfig } from './route.config';
import NewsContent from '@//view/Newss/NewsContent/NewsContent';

export default function Guards(props) {
    const [child, setchild] = useState([]);

    useEffect(() => {
        function routeConfigChild() {
            let arr = [];
            routeConfig.forEach(item => {
                if (item.childRoutes) {
                    arr = arr.concat(item.childRoutes);
                }
            })
            setchild(arr);
        }
        routeConfigChild();
    }, [])

    return (
        <Router>
            <Switch>
                {
                    routeConfig.map(item => {
                        return (
                            <Route key={item} path={item.path} exact component={item.component} />
                        )
                    })
                }
                {
                    child.map(item => {
                        return (
                            <Route key={item} path={item.path} exact component={item.component} />
                        )
                    })
                }
                <Route path="/News/NewsContent/" component={NewsContent} />
                {/* <Redirect to='/' /> */}
            </Switch>
        </Router>
    )
}