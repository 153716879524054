import React, { useContext } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import { tampCreateContext } from '@/utils/context';
import './TechnicalQuota.scss';

export default function TechnicalQuota(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;

    return (
        <div className="TechnicalQuota">
            <HeadMenu Background={1} />

            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className={`content1 section ${!global.isPc && 'md_content1'}`}>
                                <div className={`tampInfos`}>
                                    <div className="tampInfosViePic"></div>
                                    <div className="tampInfosViewText">
                                        <div className="tampInfosViewTextBox">
                                            <div className="tampInfosBoxText">
                                                {locales.TechnicalQuota1}
                                            </div>
                                            <div className="tampInfosBoxText">
                                                {locales.TechnicalQuota2}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`investmentDev section ${!global.isPc && 'md_investmentDev'}`}>
                                <div className="investmentDevView">
                                    <div className="investmentDevTitle">{locales.TechnicalQuota3}</div>
                                    <div className="investmentDevText">{locales.TechnicalQuota4}</div>
                                    <div className="investmentDevText">
                                        {locales.TechnicalQuota5}
                                    </div>
                                    <ul className="investmentDevUl">
                                        <li>
                                            <div className="investmentDevUlView">
                                                <div className="investmentDevUlViewPic">
                                                    <p className="investmentDevUlConT">{locales.TechnicalQuota11}</p>
                                                    <p className="investmentDevUlConB">{locales.TechnicalQuota12}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="investmentDevUlView">
                                                <div className="investmentDevUlViewPic">
                                                    <p className="investmentDevUlConT">{locales.TechnicalQuota13}</p>
                                                    <p className="investmentDevUlConB">{locales.TechnicalQuota14}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="investmentDevUlView">
                                                <div className="investmentDevUlViewPic">
                                                    <p className="investmentDevUlConT">{locales.TechnicalQuota15}</p>
                                                    <p className="investmentDevUlConB">{locales.TechnicalQuota16}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="investmentDevUl">
                                        <li>
                                            <div className="investmentDevUlView">
                                                <div className="investmentDevUlViewPic">
                                                    <p className="investmentDevUlConT">{locales.TechnicalQuota17}</p>
                                                    <p className="investmentDevUlConBHide">{locales.TechnicalQuota18}</p>
                                                    <i />
                                                    <p className="investmentDevUlConB">{locales.TechnicalQuota19}</p>
                                                    <p className="investmentDevUlConBHide">{locales.TechnicalQuota20}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="investmentDevUlView">
                                                <div className="investmentDevUlViewPic">
                                                    <p className="investmentDevUlConT">{locales.TechnicalQuota21}</p>
                                                    <p className="investmentDevUlConB">{locales.TechnicalQuota22}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="investmentDevUlView">
                                                <div className="investmentDevUlViewPic">
                                                    <p className="investmentDevUlConT">{locales.TechnicalQuota23}</p>
                                                    <p className="investmentDevUlConB"></p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`tampGuidanceView section ${!global.isPc && 'md_tampGuidanceView'}`}>
                                <div className="tampGuidanceViewTitle">{locales.TechnicalQuota6}</div>
                                <div className="tampGuidanceViewText">
                                    {locales.TechnicalQuota7}
                                </div>
                                <div className="tampGuidanceViewText">
                                    {locales.TechnicalQuota8}
                                </div>
                                <ul>
                                    <li>
                                        <div className="tampGuidanceViewPic"></div>
                                        <div className="tampGuidanceLiText">
                                            {locales.TechnicalQuota9}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="tampGuidanceViewPic"></div>
                                        <div className="tampGuidanceLiText">
                                            {locales.TechnicalQuota10}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <Footer section />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}