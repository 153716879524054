export const servicLists = [
    // { id: 1, time: '2020-11-06', content: '与上海银行建立合作伙伴关系，开发综合数字金融服务' },
    { id: 2, time: '2020-02-25', content: '张悦欣：美国全托资管平台TAMP进入4.0时代——中国市场潜力较大 | 国际' },
    { id: 3, time: '2020-05-27', content: '系列79「中、美财富管理 TAMP模式分析 国内市场存在问题、中美差异点、各类公司发展路径拆解」' },
    { id: 4, time: '2020-08--1', content: '系列81「美国TAMP行业 TOP20 Portfolio Management Solutions 公司list」' },
    { id: 5, time: '2020-11-25', content: '消除“数字鸿沟” 为老年人提供更便利化金融服务 | 宏观经济' },
    { id: 6, time: '2021-01--11', content: '朱光：后疫情时代科技助力金融的价值体现 | 互联网金融' },
    { id: 7, time: '2021-01--14', content: '央行支招金融如何支持碳中和目标实现 | 央行与货币' },
]

export const versionLists = [
    {
        id: 2,
        content: `
TAMP简介

TAMP全称Turn-key Asset Management Platform，全托资产管理服务平台，致力于为注册投资顾问（RIA）、经纪人和家族办公室等提供全方位的资产管理服务。TAMP提供一系列包括公募基金、ETF、特定账户在内的投资解决方案，同时提供会计对账、业绩披露、税务优化、信息报告等后台运营服务。TAMP可以帮助投资顾问更有效率地管理客户以及客户资产、提供更丰富和专业的投资解决方案、帮助投资顾问实现更好的投资收益。


美国市场现状与展望

由公募基金组合账户至联合管理家庭账户，账户的客群收入递增，投资产品维度越发复杂，TAMP带来的相应产品的投资效率也提升地越高。

在美国TAMP市场，RIA的需求量较大。中小型和独立运营的RIA公司需要高效的资源分配，而RIA在业务流程上与TAMP的形式极为相似，是TAMP服务的重要需求对象。大部分美国RIA公司，小而精，促成了TAMP提供商的形成。大量活跃的第三方理财机构和理财师是美国等成熟的财富管理市场显著的行业特征之一，第三方理财贡献美国基金销售额60%左右，而这个数字在中国占比不到5%。

根据2018 US Best TAMP的报告，适合TAMP平台赋能的美国居民家庭可投资金融资产规模预计从2019年至2022年将以每年平均5.1%的年复合增长率增长。其中，退休人群的壮大是促进适合TAMP平台赋能的美国居民家庭可投资金融资产不断扩张的主要原因。预计从2016到2022年，将会新增4万亿退休人群的可投资资产额度。预计从2019年至2022年，美国TAMP行业资产管理规模将会以20.6%的年复合增长率增长，预计行业渗透率从2017年的17.2%增长到2019年的22.65%。

美国的明星TAMP公司

2018年底Charles Schwab总平台资金管理规模为3.25万亿美元，其中TAMP账户管理规模为1.55万亿美元，占美国TAMP账户管理市场规模约41.3%。Charles Schwab同时也是其他TAMP供应商，比如Envestnet、AssetMark等资产托管机构，提供TAMP相关的配套服务及其他综合性金融服务，长期持续领跑美国TAMP市场。Envestnet以5090亿美元的资产管理规模排第二，SEI以671亿美元的资产管理规模排名第三，随后排有AssetMark（500亿美元管理规模）和Brinker Capital（237亿美元管理规模）。

AssetMark案例分析

AssetMark是一家领先的创新投资和咨询解决方案提供商，为独立财务顾问提供服务。AssetMark成立于1996年，为独立投资顾问及客户提供全面的财富管理和技术解决方案。当前核心战略可以总结为：快速拓展RIA数量、平台资产规模、TAMP技术，从而达成行业规模效应，巩固市场核心竞争力。

值得一提的是，华泰在2016年砸下7.8亿美金，从两家美国私募公司 Aquiline Capital Partners 和 Genstar Capital手上全资收购了AssetMark这家公司。华泰当年这一超前动作让证券市场引发大量讨论，有人以为华泰买的是一个智能投顾，也有人说这是一个财富管理技术平台。三年后，一切拉开帷幕。2019年7月18日，AssetMark以超定价区间1美金——22美金的价格，在纽交所挂牌，募集2.75亿美金。上市首日即上涨22%，市值近20亿美金，PE超过60倍。IPO后，华泰依然占绝对控股地位（70%）。当年华泰7.8亿美金的投资，如今市值20亿美金，翻了一倍多。而AssetMark披露的报告显示，其2017年营业收入为2.95亿美金，2018年有3.63亿美金，各占华泰这两个年份总收入的9.5%和15.3%，为华泰贡献了不少收入。

AssetMark进行战略实施的重要方针为购买以及推出新的技术业务与平台。从2015年到2019年，AssetMark一共花费了1.53亿美金，用在新技术的研发和技术团队的扩张上。而技术平台持续的迭代、高效解决方案的更新，也是为了给RIA公司和客户提供更好的服务，满足他们更多的需求。然而对新技术的投资代价也是很高的，必须通过迅速获取大量的客户资源作为支撑。于是在这期间AssetMark大约花费了超过7000万美金收购RIA公司和平台，捕获了超过70亿美金的资产规模。当然，在这一系列的动作后华泰也提供了不少资金上的支持。

AssetMark平台资产规模在过去5年中，从252亿美元增长至500亿美元规模，年均复合增增长率为14.69%；同时，平台上的财务顾问总收入从过去5年的15.3亿美元增长至59.2亿美元，年均复合增增长率为40.25%。使用平台服务的财务顾问增收效果显著，从而吸引更多财务顾问在过去5年中入驻AssetMark，形成规模效应，客户数量增长，粘性提高与客户收益增加的良性循环。

AssetMark的收入结构

一是资产性收入：来自AssetMark进行统包管理的费用收入。在该收入基础上，扣除付给策略管理人、资产管理人和其它资产统包相关的三方费用（PB、托管、投顾、市场营销等费用）后的净收入。

二是息差收入：AssetMark信托公司上托管的资产的利差收入。在该收入基础上，扣除支付给第三方行政管理人后的净利差收入。

从收入分类上看，虽然AssetMark自己的信托可以托管资产，但由于没有牌照做存贷业务，也就只能赚取银行和客户的利差收入了，无法实现较高的利息收入。如果把这些收入，换算成基于统包资产规模的费率百分比后，我们发现，AssetMark的利润空间还是不错的。0.70%左右的毛平均统包资产费率，处于行业的中上水平；扣除各种相关费用后，净统包费率在0.50%左右

从0.70%到0.50%的0.20%这部分费用中，80%的成本来自第三方的策略提供方、二级基金经理和二级投顾。在品牌、能力、资源和渠道的博弈下，AssetMark雇佣了很多实力更强的第三方资产管理人，来辅助客户的资产管理，如BlackRock，StateStreet。

虽然AssetMark有自己的公募基金，但也没有完全靠自己的产品满足客户的需求。毕竟TAMP属于一个较为中立的平台，对平台来说，更多、更快地整合各方的资产，比资产管理人的管理能力更为重要。

可借鉴的经验

第一，AssetMark拥有专业的金融投资背景的团队，运用精细化、多维度评判方式去布局投前、投中、投后产品成交与追踪策略，基于诸如交易结构、产品基本面等信息，能形成优秀且丰富的产品策略，为投资顾问做产品策略时增值保值。

第二，在交易工具不健全、市场信息不对称的中国国情下，借鉴AssetMark多维度、精细化的产品寻找、定制与跟踪策略，能完善产品的风险控制与投资策略的定制化方案；

第三，TAMP平台上若要做多维度、复杂的投资产品或产品策略组合，需要专业化的团队与精细化的产品研究与评估方案。因此，建立明星TAMP平台需要有深刻洞见力的专业化投资团队。

其他TAMP公司

一是Axxcess Wealth Management。Axxcess建立了报告平台，提供对客户财富的全面了解——包括电子和纸质版的分析报告。是一家提供投资组合全套定制的公司，帮助投资者扩大投资规模，更好地减少亏损风险。根据投资者的投资目标和风向偏好，提出投资建议并准确执行。

二是3D Asset Management。3D资产管理公司通过账户管理、投资管理和报告将潜在客户发展为客户。解决方案包括DFA基金战略模型、使用因素加权ETF模型和动态管理的全球资产配置策略等。还有一些特殊的策略，如全球增长、社会责任投资和固定收益等。

中国TAMP市场现状与展望

美国TAMP模式起步早，发展相对成熟，有着较为规范的金融市场秩序，严格的监管与成熟的金融市场发展，有超过13,000家在SEC注册的投资顾问机构，且提供的业务范围广，这些庞大的专业机构客户群是TAMP市场持续稳健发展的基石。

反观中国市场，当前只有10%的中国家庭在享受财富顾问服务，美国等发达国家达到58%，从业者收入和效率距离发达国家差距至少在7倍以上。中国资产管理和财富管理市场参与主体众多（持牌金融机构混业经营、第三方机构方兴未艾）、监管较宽松、财富管理和资产管理边界模糊。而且中国大部分理财顾问都是机构内从业者，独立理财师市场具有缺乏教育、认证和培训体系不清晰等特点。可预见未来长周期内，对标美国TAMP市场，中国TAMP行业还有漫长的专业化道路需要探索。期间，规范、专业化的中国TAMP服务商将有机会引领中国TAMP市场行业规范.

根据《2018中国理财规划师白皮书》显示，中国居民可投资资产规模从2014年115万亿元增长到2018年192万亿元，年均复合增速达13.7%。虽然2018年受宏观经济影响而增速放缓，但整体仍然保持着持续增长态势。居民财富的持续积累，以及对自身财富管理关注程度的提升，带动泛财富管理市场需求不断扩大。从客户分类看，中国的资产管理市场呈现多层次特征，极少量的头部高净值客户占据大多数资产规模。相比普通大众，超高净值和高净值人士对财富的投资渠道和诉求更加多样化，需要在资产获取、资产配置、投后管理等全流程得到更加专业化的定制服务。

根据远勤会计税务公司的研究结果，发达国家的海外资产配置比率为15%左右，而像新加坡这种自身市场规模有限的国家，海外资产配置比率更是高达37%，中国家庭的海外配置资产比例大约在4%-5%左右。这一现象的产生，是因为中国家庭的资产配置观念的不足，也是由于没有好的海外投资渠道。这时TAMP的出现不仅是提供了了解海外投资和进行海外资产配置的渠道，更是引导中国投资者进行规范化的资产配置，相当于一种投资者教育。

中国TAMP按照提供的功能性质主要分为前、中、后台三大应用环节。在中国市场中，前台产品宣传和中台交易功能得到了使用者的广泛青睐。目前中国TAMP行业现状仍处于1.0和2.0之间。

中国TAMP平台基于服务对象和功能性，可划分为分销平台、工具型工作平台、网络化行业平台和综合基础设施平台四个类别。其中，分销平台以销售推介金融产品为主，不属于本报告所指的TAMP；工具型工作平台具有较强技术属性，专注于服务财富管理机构和独立理财师；网络化行业平台在技术工作平台的基础上，通过实现C端账户体系化，支持B端和C端的终端互联，存在网络效应；综合基础设施平台为一体化的综合金融服务平台，被普遍认为将会是未来TAMP发展的成熟模式。当前阶段中国的TAMP初创公司仍是以工具型工作平台为主，处于从工具型工作平台到网络化行业平台转型的过程中。
`
    },
    {
        id: 3,
        content: `
        17年中至19年底在挖财，主要从事基金交易系统及投顾业务系统产品工作，近2年持续关注TAMP模式在中、美2国该模式发展，包括：Envestnet、AssetMark、SEI、LPL等。
        
        前言：
        
        
        上周六，向业内两位前辈请教国内TAMP模式的发展，他们分别提出了非常好的问题，尤其是肖总提出的2个问题：
        
        如何借鉴美国的发展，结合中国目前的政策、市场环境，找到适合的发展路径
        国内不同公司做TAMP平台的优势、劣势
        
        在谈话之前，我没有深入考虑过这2个问题，按照原来的计划，接下去是对中国市场存在的问题及机会进行探讨，虽然部分内容有重叠，但思考的广度与深度不够、逻辑性不缜密。
        
        
        
        而这2个问题很核心，如果要提升做TAMP这个事的成功率，终归要面对这2个问题，是商业解决方案中必须考虑的。
        
        
        
        “观察美国的发展后，这个事情在中国，不同的公司，怎么落地比较合适。”提出一个好问题，等于50%的答案，因此，我尝试寻找这2个问题的逻辑支撑与可能答案。
        
        
        
        大致思路是：
        
        市场存在问题（需求）才会有解决方案（供给），所以先观察存在的问题
        
        接着，寻找中、美行业现象共同点背后的因素，探寻差异点背后存在的市场空隙
        
        最后，结合不同公司自身的资源优势，寻找合适的发展路径。
        
        
        
        因此，整体结构分为3个部分：
        
        国内市场存在的问题
        中、美之间的差异与共同点
        国内不同公司做TAMP的优劣势、发展路径
        章节中部分内容，在之前系列文章中有所描述，因此我只做备注，不再详细展开。
        
        术语：
        TAMP：Turnkey Asset Management Platform 英文直译是交钥匙资产管理平台，我们可以理解为是一站式用户资产管理平台，用户将资产账户交由理财顾问打理
        AUM：Assets Under management 资产管理规模
        FA：Financial Advisers 理财顾问，偏向理财规划
        IFA：Independent Financial Advisors 独立理财顾问
        IA：Investment Advisers 投资顾问，偏向交易，与IA部分工作重叠
        RIA：Registered Investment Advisor 注册投资顾问，需在SEC或州监管机构注册
        Wirehouse：通过互联网交易的券商，比如SHCWAB、AMTD、ETrade
        IBD：Independent Broker Dealer 独立证券经纪商
        Custodian：托管人。在美国，一些经纪商，同时也是托管人
        Hybird RIAs：RIAs＋IBD 在美国理财投资与股票投资业务进行分离
        Family office：家族办公室，为超高净值家族提供理财服务
        Mutualfund wraps：共同基金账户
        ETF wraps：ETF账户
        SMA：个人独立投资账户
        UMA：个人统一投资账户
        UMH：家庭统一投资账户
        IRA：Individuals Retirement Account 美国的1种个人退休账户，比较复杂，有Traditional与Roth两种
        401K：类似于中国社保养老金，也分Traditional与Roth两种
        Commission-Based：基于佣金的收费模式，比如证券经纪业务的交易佣金
        Fee-Based：即Advisory Fees，可理解为基于服务费用的收费模式
        Subcriptions-Based model：可理解为基于订阅／使用的收费
        
        
        05
        —
        
        市场存在的问题
        
        Highlight：发现问题、定义问题
        
        5.1）需求端
        
        
        
        人们不断增长的金融服务需求与服务供给能力之间的不匹配。
        
        
        
        具体定义4个问题：
        
        普通民众财富持续增长，但面临购房、养老、医疗意外、子女教育、财富传承的金融服务需求，与当前金融服务能力之间的不匹配，需高效 & 个性化的服务解决方案：
        
        用户本质需要的是生活场景对应的个性化、差异化的金融服务解决方案，而不是FA向用户出售产品，告知用户有多少percentage的年化收益的一个销售过程。
        
        目前多数大众用户只关注投资收益。因此FA要做的是将收益、风险、实际用户的生活需求关联起来。将3个点，构成一个面，然后再给用户看，角度不同，信息不同，用户才有可能发生理念上的转变；
        
        大众富裕、高净值用户阶层的投资理念相对领先，与目前买方投顾的理念相较契合，这也引申出目标市场细分选择的问题。
        
        
        
        普通民众面临产品选择风险、投资结果风险不断增加的风险（系列76）。
        2018年，人行、银保监会、证监会发布的资管新规明确要求破除刚兑、产品净值化。
        这好比，用户本来套着游泳圈横渡长江，万一大风大浪，虽然有风险，但基本心理有谱。现在不给游泳圈了，并且要求游个来回。对于普通用户来说，这无疑增加了投资理财的风险，使得投前－鉴别产品风险、投中－产品风险监控，都变得异常重要。
        我们可以观察到，随着金融市场的逐渐开放，不断有新产品出现、消亡（贵金属、商品、外汇交易、P2P等），随着破除刚兑过程的深入，产品数量的增加，投资者必然会面临更大的风险不确定性，对普通投资者而言，识别这些产品的风险，需要大量时间成本，这是低效的。
        
        个人收入、税赋、财富资产这3者将会更加紧密（系列76）
        我们站在另一个角度来看待这个事情，税收对象只有2类，第一是企业，第二是民众。见下图：2015年分税种收入及占比，大家就知道未来的趋势
        美国的个人税赋分的很细，在系列76有仔细介绍。
        因此需要有平台提供整体解决方案
        
        理财服务费用差异化的问题，国内投资者还未觉知，投资理财可以按服务与效果付费，在国外这是普遍现象。
        在美国，RIA firm相当于国内市场参与者三方财富的角色，作为firm的FA，只有Advisory Fees这1项主要收入，即通过提供咨询服务收取服务费，此费用可以按咨询服务时间或AUM的Percentage(一般采用后者)，多数不超过AUM的2%。也就是用户最终是为服务与结果在付费。
        而在国内，用户是为销售在支付费用
                
        5.2.1 ) 独立FA/工作室/家办所面临的展业需求与支撑资源不匹配之间的问题
        
        
        
        先看FA独立展业的5个必要条件（系列77）
        
        
        有持续稳定的用户来源与可信赖的用户关系，即个人信誉背书已远大于公司信誉背书；新型垂直用户渠道，他们有持续稳定的用户来源，获得了用户信任，当平台找到标准产品供应商，进行推荐销售时，本质是充当了FA的角色。
        更多收入与自我操作的空间
        可以独立找到产品供给方，进行独立销售，这是交易执行模块主要解决的问题。
        可独立完成账户管理、尽调、合规、签约、文本报告等事务性工作。这是事务型模块主要解决的问题
        可独立完成产品及组合的风险分析、组合构建、调仓等资产管理与投资管理工作。这是组合管理、投资管理模块主要解决的问题。
        
        目前，存在2个最大问题：
        国内缺少好工具支持FA进行以上操作，多数TAMP模式参与者做了2件事，第一，忙着赚钱，比如做资产撮合方，因为这个事情来钱快。第二，做一些不实用的功能，比如Robot Advisor、NPL，脚踏实地做工具的公司不多。
        极少部分想做的公司，没有牌照资质，只能做一些辅助模块。
        
        5.2.2 ) 不断涌现的新型用户垂直渠道面临的商业变现及运营问题
        
        
        诸多新型用户垂直渠道、媒体，需要有平台提供资产交易的能力，并且能够管理运营好自己池子里的用户，前者是交易能力，后者是运营能力问题。
        
        具体有2个问题：
        变现问题－去中心化的用户流量渠道，越来越多的自媒体可以通过不同渠道获取用户流量，除了广告、课程，如何变现？
        如何高效运营管理－有没有工具可以提供用户全周期管理？
        
        
        5.3）供给端
        
        5.3.1）传统三方财富公司面临的监管政策加强与商业模式变革的问题
        
        2018年，资管新规的出台，与2019年，中国版投顾法案的推出，使得传统三方，尤其是中小三方面临2个问题：
        
         如何活下去的问题，固收产品供给逐渐减少，权益产品的销售难度比较高，商业收入可预见的减少
        
        在没有投顾牌照的情况下，从销售至服务理念转化的过程中，如何找到适合自身的商业模式
        
        
        5.3.2）持牌金融机构面临的理念转变问题
        
        
        从美国的券商、银行投顾业务部门发展历史来看，创新脚步总是落后于新兴势力，因其牌照门槛与悠久的历史声誉，所以在交易托管、用户流量侧占有较大优势，因此在投顾领域保持一席之地。
        
        
        
        如何转变观念，主动拥抱独立理财的趋势，也是中国1300多家银行、120多家券商面临的问题。
        
        
        我们反过来看，美国TAMP平台现有的一些用户群体，可能会得到一些启发。
        
        LPL的目标用户群体包括中小银行、保险公司、信用社机构，其2019财报中有几段描述：
         We believe we are the market leader in providing support to over 2,500 financial advisors at approximately 800 banks and credit unions nationwide.
        We also provide support to over 3,000 additional financial advisors who are affiliated and licensed with insurance companies. 
        
        而Envestnet的目标用户，包括全美前20大银行中的16家，前50大财富管理公司与经纪商中的43家[3]：
        More than 4,700 companies, including 16 of the 20 largest U.S. banks, 43 of the 50 largest wealth management and brokerage firms, over 500 of the largest registered investment advisers (“RIAs”) and hundreds of internet services companies leverage Envestnet technology and services. 
        
        Envestnet solutions enhance knowledge of the client, accelerate client on-boarding, improve client digital experiences and help drive better outcomes for enterprises, advisors and their clients.
        
        
        
        
        
        5.3.3）行业监管政策取向及其它问题
        
        
        主要有3个问题：
        
        
        
        2至3年左右，投顾资质的准入门槛是否会降低
        目前的投顾牌照准入门槛还是相当高的，比如：要求申请开展投资咨询业务的机构净资产不低于1亿元；强化股东资质要求。参照人民银行等三部委联合发布的《关于加强非金融企业投资金融机构监管的指导意见》，要求持股5%以上股东净资产不得低于5000万元，控股股东最近3年连续盈利，净资产不低于5亿元，具有良好的资本补充能力，最近 3年未被采取重大监管措施等，并提出“一参一控”要求
        
        
        收入形态的规则
        
        
        
        买方投顾的收入，是否只允许有服务费，不允许有佣金等其他收入？还是会允许两者并存？亦或设置一个过渡时间节点，但最终只保留fee_based模式
        
        
        
        这是最为核心的，决定了FA跟公司能不能赚到钱，如何赚钱？如果当前严格按服务费收入来执行，在只开放公募基金品类的市场状况下，按AUM的0.1%至0.2%的收入毛利微薄，多数公司较难覆盖运营成本。
        
        
        投顾业务是否考虑纳入其它大类资产
        刚才提到，因公募基金品类毛利率较低，因此只能做规模，但互联网流量具有明显的寡头垄断效应，因此靠近C端的大部分公司无法覆盖运营成本，只有扩充品类，提升单个用户的LTV这条路；这涉及到不同牌照及资质问题，比如保险需要经纪牌照，银行理财是否需要专属代销牌照？
        
        
        
        
        06
        —
        
        中、美差异点与共同点
        
        Highlight：生态环境不同
        
        
        6.2）相同点
        
        
         中、美用户都面临专业复杂的金融市场与日渐增长的投资理财风险
        
        此点不再展开描述。
        
        
        
        中、美监管机构鼓励更多的机构投资者进入市场，降低个人投资者的比例
        海通证券基金研究中心数据显示，截至2019年12月31日，主动权益基金（包括股票型基金、混合型基金，剔除指数基金和封闭式基金）在过去10年整体上涨96.52%，年化收益接近7%，大幅超越沪深300指数涨幅。但是我问身边的人，70%的人告诉我没赚钱。
        我认为这也是为什么监管层,推出中国版投顾法案的出发点之一，在这个专业的市场，普通人赚钱还是比较困难，需要专业机构做专业的事。
        
        经纪业务逐渐衰落的趋势
        去年10月份左右Schwab主动将股票交易佣金降至0，然后传出收购AMTD，Schwab收入主要是由利息收入、资产管理(包括服务收入)这两块构成，占90%，经纪业务收入只占6到8个点左右。
        
        同时，Schwab是美国市场最大的RIA资产托管机构。
        
        
        中、美在面临养老的难题上，鼓励民众持有更多商业形态的养老计划
        这点会促使公募基金、保险产品在个人财富管理中地位提升，两国政府不约而同将公募基金作为了切入点。
        截止2019年Q4，401K的总规模大约6.2万亿美元，其中有65%投向了Mutual Funds，大约是4.2万亿美元，2019年末，美国Mutual Funds规模大约在17.7万亿美元，401K约占22%比例（系列76）。
        
        中、美市场在技术上都是成熟的，从研发人力来说，中国更具备优势
        这点不再展开描述。
        
        个人税赋、收入、与财富3者之间的关系更加紧密
        这点不再展开描述。
        
        
        
        07
        —
        
        不同公司做TAMP的优劣势、发展路径
        
        Highlight：借鉴与复制
        
        
        7.1）市场细分、格局与优势
        
        我们将中、美两国的市场参与者按资源禀赋优势、公司类别，2个维度划分，观察下有什么差异与不同，因为这个部分比较重要，所以单独拿出来。
        
        
        重点小结：
        
        没有牌照优势，即交易、托管的能力，不可能成为TAMP平台。美国能跑出来，是因为生态环境的不同，券商、银行开放这样的能力给到第三方。在中国目前的市场、政策环境行不通。
        
        没有投顾业务牌照，很难成为TAMP平台，因为无法按投顾模式收费。
        
        传统金融－券商系，如果看中、美券商的优势分析，结合美国的发展历史，国内券商会诞生平台级的公司诞生（华泰已经占优），但这需要进行自我变革。案例是SCHWAB的经纪业务收入只占10%，多数券商很难做到这一步。
        
        互联网－独立三方系，一定会有公司跑出来，成为独立三方工具平台，因为对比券商、银行、三方系，它的出发点更纯粹。相对券商系，它的优势在于更专注与产品、功能、系统的整合。
        
        互联网－超级平台系，可能演变为金融服务三方平台，利用流量优势进行高效分发，可以想象为是金融服务领域里的天猫；但很难成为工具平台，至少阿里不会，因为它一定会利用C端流量。
        
        

        7.2）各家优势与发展路径小结
        
        一、传统金融－券商系
        优势
        券商牌照－股票交易必须通过券商；账户资产数据也必须通过其系统
        金融优势－天然具备组合管理、投资管理优势
        资本优势
        劣势
        无法称之为独立的三方工具平台，因为经纪业务是基本盘，一定会存在内部冲突
        
        发展路径：
        
         第一，夯实业务优势，积极申请券商投顾牌照，近水楼台一定先摘月。
        第二，拓展业务半径，主动与基金投顾牌照渠道合作，构建更为宽泛的服务范围
        第三，构建工具化开放平台，将托管、交易执行、账户管理、组合管理、投资管理、底层功能模块化，同时，整合third-party的工具，如同电脑主板一样，具备接入与输出能力，提升前端体验，构建工具开放平台；因目前阶段股票交易必须经过券商，因此其他类型平台想要提供股票品类的账户资产服务，必须与券商平台合作，这是一个非常大优势。
        第四，组织架构调整，设置为IFA服务的部门，协调公司资源予以支持。
        
        二、传统金融－银行系
        优势
        占据线下C端用户入口，掌握第一手用户资产资料，对人群具有天然品牌背书优势
        银行理财子公司的牌照，意味业务半径更为宽泛
        资本优势
        劣势
        存在内部之间的业务利益冲突
        业务发展较为稳健，反过来制约新模式的探索
        
        发展路径：
        
         第一，理念的转变，因为IFA渠道，天然与银行私行业务或服务大众富裕阶层的部门有利益冲突，如果要做这个业务，内部的理念必须转变，这是一个共生成长的业务，因为是需求底层的用户端选择，促使进化为IFA的服务形态。
        第二，整合业务资源，发挥优势业务。围绕借贷、现金管理基础业务，将投顾业务整合进去，形成专业、稳定的投顾服务风格；同时，牌照资源也需要争取。
        第三，即使不做这个业务，也可考虑将TAMP技术运用在私行、高净值业务，使得内部运营效率、用户服务体验得以提升。
        
        三、传统三方系－诺亚、大唐
        优势
        线下商业模式成熟
        掌握高净值用户资源
        金融服务上，组合管理、投资管理业务占有一定优势
        劣势
        缺少投顾牌照资质
        无法称之为独立的三方工具或服务平台，与原有业务存在利益冲突
        
        发展路径：
        
         第一，争取入围资格，申请基金代销、基金投顾牌照
        第二，隔离原有业务，设立新公司或进行收购，提供为IFA服务的开放工具平台。这里一定要将原有业务进行隔离，从品牌上也隔离。
        因为如果我是成熟独立的FA，知道所使用的平台是诺亚的，所有用户信息资料都在诺亚公司的服务器上，是会有很大顾虑，我会优先考虑第三方独立平台，甚至去券商平台。
        而这也是为什么诸多头部RIA选择LPL、Envestnet而不去SCHWAB、AMTD的原因之一
        
        第三，如果第1、2点都走不通，可以考虑走产品型路线，提供Financial Planning、Portfolio Management、Investment Strategy服务工具，结合自身优势，占据一个细分领域的位置
        
        四、互联网超级平台－蚂蚁、腾安
        优势
        占据线上流量优势
        产品、技术能力占优
        试错成本低、制度更为灵活
        资本优势
        劣势
        缺少金融基金
        无法成为独立三方工具平台，因为一定会利用C端优势，而一旦利用，意味着用户永远是平台的，不属于FA
        
        发展路径：
        
        
        第一，构建开放、共享的服务交易平台，参与规则制定。邀请行业参与方入驻，比如IFA、工具商、资产管理机构、投资策略机构方共同入驻，利用已有的用户流量资源，进行高效分发，形成一个从用户(买家)、FA(中介)、资产管理机构(卖家)、投资策略机构(服务商)、工具提供商(服务商)为一体的服务交易平台。可以想象为是金融服务领域里的天猫。
        
        这里关键在于如何吸引优质投顾入驻。因为平台有流量，所以冷启动相对容易。
        
        当然，还有一种做法就是全部自己来，从头到位自己来做，对于超级平台来说，这也可以，因为巨大的C端流量资源、资本实力、技术能力可以让平台快速试错，兼并收购形成生态闭环。
        
        
        
        第二，明确自身定位，参与规则制定，将自身定位于土壤、空气、水，是Iaas、Paas的角色
        第三，突出产品技术优势，快速迭代尝试
        
        
        
        五、互联网公司－技术 & 业务派
        优势
        产品、技术能力占优
        试错成本低、制度更为灵活
        劣势
        缺少牌照资质
        缺少金融基金
        缺少资本加持
        缺少冷启动用户资源
        
        发展路径：
        
        
        第一，争取入围资格，申请投顾牌照、代销牌照
        第二，走垂直产品型路线，提供Financial Planning、Portfolio Management、Data Analysis、CRM、Tax solution等服务工具，先结合自身优势，占据一个细分领域的位置
        第三，专注在增值服务模块，提供FA的能力培训课程、考证服务课程、独立展业服务等
        定位与整合工具平台，将所有TAMP的功能组装在一起，自身充当电脑主板的角色(或只做其中一个模块)，把所有功能模块化、可插拔。
        这是最后可以选择的模式，因为，涉及到交易执行业务，在国内必须要牌照，如果没有牌照就必须要找到相关机构，要做好具有一定难度。这需要取决于Third-party是否授权，并且不同Third-party整合后的体验也是一个问题，最后，成本如何分摊也是需要解决的问题。
        
        
        六、独立三方系－盈米
        优势
        交易、投顾牌照优势
        产品、技术能力占优
        试错成本低、制度更为
        劣势
        缺少金融基因
        需更多资本加持
        缺少冷启动用户资源
        
        发展路径：
        
        
        积极获取其他资产品类的销售牌照，增加大类资产，比如保险、券商资管、阳光私募、银行理财。
        
        因为用户的金融需求一定是多样化的，意味着组合管理的收益与风险的追求必定是多样化的，与其在一些事务管理、投资管理上花费很多时间精力，不若力出一孔，先把大类资产的交易打通。然后用高频带低频的方法，通过投顾服务的形式配置其他大类资产。
        
        第二、明确独立、开放、共享的工具开放平台定位，邀请行业参与方入驻，参与规则制定，比如IFA、工具商、资产管理机构、投资策略机构方入驻；加强核心业务优势：自身交易执行、组合管理、事务型管理；同时，欢迎Third－party提供投资管理与辅助功能管理产品服务，所有功能模块化，具备接入与输出能力，构建独立的工具开放平台；
        
        这与互联网超级平台的出发点不同。从用户归属来看，超级平台的用户，即使经过FA服务，仍属于平台。但，工具平台跟用户不发生直接关联。用户一直属于FA。
        
        第三，选择高端IFA、家办作为第一突破口，将垂直用户渠道作为第二突破口，选择金融机构作为第三突破口。这几个战场的实施难度依次递增。
        
        这里的冷启动增长起始点有3个选择，首选落在高端IFA、家办。其次落在垂直用户渠道，最后，可以落在金融机构上，前者已自带用户，模式成熟；中者自带流量，但是需要投顾服务能力加持；后者用户、利润都较为稳定。
        
        
        以上，就是我对不同类型公司如何落地的解读。
        
        `
    },
    {
        id: 4,
        content: `
        目前负责盈米启明BU互联网产品工作，主要聚焦TAMP系统及公募基金交易系统相关，近3年持续关注TAMP模式在中、美2国该模式发展，包括：Envestnet、AssetMark、SEI、LPL等。

        看到一篇好文章，说的是全美TOP50的Portfolio Management Solutions工具list，我做了一些调整，保留了20家，其中有2家没看过，前10家花了较多时间精力，个人认为价值比较高，与大家分享：
        
        这些工具平台，实操中还包括其它业务流程：
        
        1. Account opening
        2. Model management
        3. Portfolio construction
        4. Portfolio accounting
        5. Portfolio rebalancing
        6. Trading
        7. Reconciliation
        8. Performance Reporting
        
        
        国内在线上，目前跑通的业务模式与美国不同，归纳为4点：流量平台（客户）＋个人品牌（大V）＋投资能力（策略）＋变现（课程／广告／导流／交易）为结构的变现模式。这里有信息传播路径、文化背景、监管政策等原因造成。
        
        所以，不能照抄美国，需结合国内实际情况，这是我进入启明，从门外到屋里，接触下来最大的感受。这个话题也比较有意思，以后有机会再唠。
        
        言归正传，名单如下：
        
        Envestnet Tamarac
        SEI Wealth Platform
        SS&C Advent Black Diamond
        AssenMark
        LPL Financial ClientWorks
        Orion Advisor Tech
        Oranj
        Pershing NetX360
        Vestmark One
        Albridge
        Morningstar Office
        Riskalyze AutoPilot
        Advisor360
        Charles Advisor Services
        E*Trade Advisor Services
        Fidelity Advisor Services
        Advyzon
        Blueleaf
        Panoramix
        Vise
        `
    },
    {
        id: 5,
        content: `
        随着中国互联网、大数据、人工智能等信息技术快速发展，智能化服务得到广泛应用，深刻改变了生产生活方式，提高了社会治理和服务效能。但同时，我国老龄人口数量快速增长，不少老年人不会上网、不会使用智能手机，在出行、就医、消费等日常生活中遇到不便，老年人面临的“数字鸿沟”问题日益凸显。近日，一位94岁的老人为激活社保卡，被子女抬着在银行柜机前进行人脸识别，引发社会热议。日前，国务院办公厅印发《关于切实解决老年人运用智能技术困难的实施方案》(以下简称《实施方案》)，就进一步推动解决老年人在运用智能技术方面遇到的困难，坚持传统服务方式与智能化服务创新并行，为老年人提供更周全、更贴心、更直接的便利化服务作出部署。

        
        “数字鸿沟”困扰老年群体
        
        随着金融科技的持续推广，金融服务的使用程度稳步加深、可得性持续改善、质量不断提升，但是还存在一些问题。
        
        从94岁老人被抱起激活社保卡，到老人冒雨用现今交医保被拒，关于老人"数字鸿沟"的话题在网络不断发酵，成为民生关注的热点。在疫情防控期间，“健康码”的使用也引发了全社会对于老年人使用智能技术困难的关注和关怀。
        
        根据民政部公布的最新预测数据，到“十四五”期末，全国老年人口将突破3亿，我国将从轻度老龄化迈入中度老龄化。
        
        据中国社科院国情调查与大数据研究中心等机构联合发布的《中老年互联网生活研究报告》显示，46.3%的中老年人从未用过手机支付功能，36.4%的中老年人偶尔用，只有17.4%的中老年人经常用此功能。
        
        专家表示，中国信息化、城市化与老龄化形成的“三化”同步发展引发了严重的代际"数字鸿沟"。数字技术的发展及其应用与老年人能力之间的差距越来越大，所带来的不平衡与不适应也越来越强烈。
        
        降低“数字鸿沟”带来的不利影响
        
        如何帮老年人迈过“数字鸿沟”是一个新考题。10月15日，央行发布《中国普惠金融指标分析报告(2019年)》建议，应协调推进“线上+线下”普惠金融业务发展，降低"数字鸿沟"的不利影响。
        
        10月21日，中国人民银行行长易纲出席了“2020年金融街论坛年会”时也曾提出，要助力保留传统的、有效的金融交易渠道和服务方式。“在很多人享受科技带来便利的同时，也要考虑部分老年人和其他的一些人群，他们没有智能的设备，由于数据鸿沟给他们带来的不便，老年人说了，我没法去公园，甚至没法上公共汽车，这是不行的，这些传统的渠道，必须要对他们开放。”易纲说。
        
        《实施方案》提出，要在政策引导和全社会共同努力下，有效解决老年人在运用智能技术方面遇到的困难，让广大老年人更好地适应并融入智慧社会。到2020年底前，集中力量推动各项传统服务兜底保障到位，抓紧出台实施一批解决老年人运用智能技术最迫切问题的有效措施，切实满足老年人基本生活需要。到2021年底前，围绕老年人高频服务事项，推动老年人享受智能化服务更加普遍，传统服务方式更加完善。到2022年底前，老年人享受智能化服务水平显著提升、便捷性不断提高，线上线下服务更加高效协同，解决老年人面临的“数字鸿沟”问题的长效机制基本建立。
        
        20项举措便利老年群体日常生活
        
        老年人日常生活中的出行、就医、消费等活动，也将得到更多便利。《实施方案》聚焦老年人日常生活涉及的出行、就医、消费、文娱、办事等7类高频事项和服务场景，提出了20条具体举措要求：
        
        一是做好突发事件应急响应状态下对老年人的服务保障，包括完善“健康码”管理便利老年人通行、保障居家老年人基本服务需要、在突发事件处置中做好帮助老年人应对工作。
        
        二是便利老年人日常交通出行，包括优化老年人打车出行服务、便利老年人乘坐公共交通、提高客运场站人工服务质量。
        
        三是便利老年人日常就医，包括提供多渠道挂号等就诊服务、优化老年人网上办理就医服务、完善老年人日常健康管理服务。
        
        四是便利老年人日常消费，包括保留传统金融服务方式、提升网络消费便利化水平。
        
        五是便利老年人文体活动，包括提高文体场所服务适老化程度、丰富老年人参加文体活动的智能化渠道。
        
        六是便利老年人办事服务，包括依托全国一体化政务服务平台优化“互联网+政务服务”应用、设置必要的线下办事渠道。
        
        七是便利老年人使用智能化产品和服务应用，包括扩大适老化智能终端产品供给、推进互联网应用适老化改造、为老年人提供更优质的电信服务、加强应用培训和开展老年人智能技术教育。
        
        业内认为，针对老年人面临的“数字鸿沟”，需要加强数字化能力提升的培训；与此同时，在需要进行扫码、挂号和智能支付的场景，加大对老年人引导和协助的力度；以及开辟绿色通道，进一步帮助有需求的老人。
        `
    },
    {
        id: 6,
        content: `
        2021年是“十四五”规划的开局之年，也将是后疫情时期的“恢复性增长之年”。《中共中央关于制定国民经济和社会发展第十四个五年规划和二〇三五年远景目标的建议》提出，构建金融有效支持实体经济的体制机制，提升金融科技水平，增强金融普惠性。进入新阶段，科技在助力金融防范风险、助力实体经济方面将发挥更大作用。



2020年，突如其来的新冠肺炎疫情使中国经济发展受到了前所未有的剧烈冲击，对金融行业“非接触式”、精准服务能力也提出了挑战，同时也为金融科技发展提供了前所未有的契机。云计算、大数据、人工智能、区块链等新兴技术在金融行业的深入应用，推动了金融服务转型升级，也为金融业在抗击疫情方面发挥了重要作用。

满足“非接触式”金融服务需求

受疫情影响，金融领域线下场景受限。一方面，大量金融服务需求，从线下迁移到线上，如信贷、理财、保险等个人业务线上化提速；另一方面，受疫情影响很多员工无法到岗，金融业务的线上化、自动化成为刚需，金融机构线上化、智能化的需求迫切。金融科技发挥在智能获客、大数据风控、机器人流程自动化（Robotic Process Automation，简称RPA）等方面优势，为客户提供全流程解决方案。例如，在金融机构的客服场景下，客服复工人数受疫情影响严重，语音机器人既能避免人员聚集的风险，又能降低人工成本，且可以杜绝语言暴力等风险，受到金融机构的欢迎。疫情高峰期间，某全国性股份制银行客服无法到岗，利用金融科技公司提供的语音机器人承担了客服的大部分工作，3月后，随着客服逐步复工，通过“人+机”结合的方式，减低了30%左右的人工成本。证监会和银行业协会数据显示，疫情发生以来，通过网络终端、手机应用程序（Application，简称App）等线上渠道进行证券交易的比例超过95%，各银行机构线上业务的服务替代率平均水平达96%。

为中小微企业提供金融精准支持

与大型企业相比，中小微企业资金压力大、抗风险能力弱。在疫情影响下，中小微企业的生产经营受到严重冲击。如何为中小微企业纾困，成为各政府部门、金融机构的政策核心，也成为金融科技企业的关注焦点。借助人工智能和大数据建模技术，在个人信用基础上叠加企业信用、企业画像，金融科技可以为中小微企业提供兼顾风控成本与安全性的、行之有效的解决方案：利用光学字符识别（Optical Character Recognition，简称OCR） 对申请人的工商信息、交易信息、税务信息、用水用电、司法信息进行识别，持续丰富小微企业数据维度，同时利用图计算、自然语言处理技术对企业互联网信息进行挖掘，分析其经营能力和经营状况，基于小微企业主画像精准度的提高，能够实现零抵押、全自动与低风险、高效率的并行。此外，应用金融科技，也有利于降低人为干预、人为操作的空间，有效防范道德风险，提高风险判断的客观性。

后疫情时代的金融科技价值


当前，国内疫情已基本得到控制，复工复产有序推进；但国外疫情蔓延形势仍然非常严峻。在全球经济深度调整期，疫情对经济的影响仍然面临较大不确定性。在中国经济基本面长期向好这一最大确定性之下，我们应该练好内功，以更积极的态度应对各种“不确定性”的挑战。

加速回归风险管理本质

过去互联网金融之所以聚集一些风险，主要是因为过于强调效率的提升，忽视了风险的管理。此次疫情可以说是对企业的一次全链路压测，其中会暴露出很多商业模式的问题、组织效率的问题、灾备能力的不足。比如，在互联网金融领域，政府在2019年围绕消费者权益保护和风险防控，出台了多项监管政策：包括加强利率管控、强调数据合规应用、规范催收管理等，一些缺乏金融科技支持、风控能力不足的平台就出现了较大的业务波动，无法在低利率下保持盈利。而本次疫情，放大了本来就存在的风险，让原本带病的企业情况更加艰难。所有金融行业和金融科技的从业者，如果能前瞻性地应对风险和及时调整，可以使风险管理和精细化运营水平提升到新层次，推动战略和经营管理更上一个台阶。

金融业务对“免疫力”有更高的要求，要遵循经营规律，任何时候都要留下充足的风险缓冲，以备不可预期的风险。风险的稳定性是金融企业展开一切经营活动的基础。所有运营决策都是在风险稳定的基础上做的。任何金融企业都会面临像疫情这样的宏观风险，但比拼的是危机到来时，谁的资产稳定性好、谁的波动小。稳定的风险建立在对客户的深度洞察基础上。这次疫情给了金融行业难得的机会，恰好能看清楚用户中，哪些是具有稳定风险表现的客群。金融机构可以不断探索加深用户洞察的路径，分客群精细化运营管理，从而大幅提升风险稳定性。

丘吉尔说过：“不要浪费一场好危机”。金融科技公司应建立以风险管理为核心的全链路金融科技布局，包括信用风险管理、操作性风险管理和宏观风险预警。在信用风险管理方面，让风险管理贯穿获客、准入、贷中管理、贷后管理等全业务流程。例如，在获客方面，可以通过技术识别有还款能力和还款意愿的优质客户；贷中管理方面，实时监测客户状态变化，调整风险敞口；贷后管理上，基于风险预测进行差异化的贷后管理。在宏观风险预警方面，可以针对不同的行业、区域进行监控，并针对多头共债风险进行预警。同时，推动RPA深入到业务流程重要环节，如信审、客服、催收等，降低合作伙伴操作性风险。

为实体经济输血护航

尽管疫情趋于平稳，经济开始反弹，金融机构面临的挑战仍然艰巨：第一，对银行来讲，整个资产构成会发生变化，过去可能在资产端以大型企业、优质企业和优质民营企业为主，现在向普惠、向小微、向零售金融转型压力增大；第二，过去的风控政策都是在高速增长的环境下制定的，没有穿越过真正的周期。风险、存量资产也会给传统金融机构带来压力。

这意味着，金融行业对金融科技的应用，不能只是线上展业这样的“小修小补”，而是通过金融科技的应用，真正让金融资源精准覆盖到经济体中的各个基本单元。比如中小微企业，此次疫情对于它们而言是一次生死存亡的考验。中小微企业是国民经济的重要组成部分，疫情之下，国内外需求下降，中小微企业困难凸显，为中小微企业精准输血，对于稳就业、稳住中国经济基本盘意义重大，这既是整个金融业最现实、迫切的责任，也是金融科技发挥价值的重要阵地。

对于金融科技公司来说，在技术服务上，可以开放更多技术能力给金融机构，助力金融机构提升服务中小微企业能力。如线上风控能力输出，让金融机构在现阶段缺乏线上数据积累的情况下，可以迅速发展线上金融业务，实现业务模式的完善和迭代；开放平台获客能力，如基于用户洞察所形成的用户运营能力输出，助力金融机构实现客户价值最大化。同时还可以运用大数据、云计算等技术建立风险定价和管控模型，改造信贷审批发放流程，提高客户识别和信贷投放能力，打通企业融资“最后一公里”堵点，切实满足中小微企业融资需求。

在产品和场景上，开放产品创新能力，如基于风险定价能力设计出的多样化金融产品，更好识别优质小微企业客户，保证资金使用场景，有效把控信贷风险。

金融科技要成为扶持中小微企业的“稳定器”和“加速器”，有三条路径：一是以风险管理为核心，发挥大数据风控的价值，降低中小企业融资的信息不对称，帮助银行管理好在经济下行环境中面临的风险翘头等风险；二是助力银行打造全流程线上化的服务模式，提升金融服务的可获得性；三是推进应用人工智能技术，提高信贷决策效率，降低中小微企业服务成本，推动综合融资成本的下降。


随着中国经济逐渐恢复，金融行业转型升级步伐将持续加快，金融科技在此过程中将发挥重要作用，未来金融科技发展有几个趋势值得关注。

合规经营 服务实体经济

金融科技在催生新业态、新模式的同时，也暴露出部分风险因素。近年来，中国人民银行、银保监会等监管部门，围绕风险防范、消费者权益保护、数据安全出台了多项政策，对金融科技创新进行规范，确保金融科技始终走在守正向善的路上。金融科技企业应该严格遵循相关政策法规合规经营，把握和金融机构合作的尺度，发挥各自的优势，降低中小微企业服务成本，推动综合融资成本的下降，助力实体经济。

金融基础设施建设加快推进

经过多年建设，我国逐步形成了为货币、证券、基金、期货、外汇等金融市场交易活动提供支持的基础设施体系，功能比较齐全、运行整体稳健。但随着金融科技的快速发展，对人工智能、大数据中心、第五代移动通信技术（5G）基站等新型金融行业基础设施提出了更高的要求。目前我国互联网协议第六版（IPv6）、5G等网络基础设施正在加速推进，为金融科技服务提供高速率、广普及、全覆盖的网络基础设施。各类支付清算系统功能也在持续完善，为移动支付创新提供坚实的支付基础设施。

关键技术应用加速落地

疫情让金融机构充分意识到金融科技的价值，未来金融机构将更加积极拥抱金融科技，云计算、大数据、人工智能、区块链等技术应用也将加快落地。大数据技术将在信贷风险管理、客户画像等方面提升精准性和风险预警的时效性，人工智能将在业务咨询、智能客户等方面应用更广泛，云计算将有效降低金融机构的信息技术（IT）成本和可扩展性，区块链技术将更好地发挥其在防篡改、可追溯、多方协同等方面的优势。
        `
    },
    {
        id: 7,
        content: `
        碳金融市场在应对气候变化、实现“30·60目标”方面具有重要作用。我国碳市场发展基础坚实、潜力巨大，但还面临政策不完善、金融化程度低、碳市场作用发挥不充分等问题。推动碳金融市场全面规范发展，既要夯实产业基础和现货市场，也要构建和完善碳金融制度体系。在健全政策框架和风险防控机制的基础上，未来可适当加快碳金融市场建设与产品服务创新。


        文/人民银行研究局课题组
        

        在全球更加关注和积极应对气候变化之时，碳排放权交易和碳金融市场的作用越发引人注目。本文结合国际碳市场的发展经验，在总结我国碳市场运行情况、存在问题及成因的基础上，提出推动我国碳金融市场全面规范发展的政策建议。
        
        全球碳市场最新进展及主要矛盾
        
        最新进展和疫情影响
        
        随着70多个国家和地区承诺2050年实现净零排放，全球碳市场积极扩张，目前正在运行的碳排放交易体系有21个，覆盖的碳排放约占全球排放总量的10%。截至2019年末，碳市场累计筹资逾780亿美元，资金用途主要用于支持能效提升、低碳交通、弱势群体等。发达国家碳市场在运行过程中，金融机构参与日益广泛，交易所作用不断增大，交易的金融工具品种日益丰富。目前，全球碳金融市场每年交易规模超过600亿美元。其中，起步最早、市场交易最活跃的品种是碳期货，年交易额占1/3。
        
        新冠肺炎疫情对碳市场造成较大影响。疫情全球暴发之初，碳市场严重受挫，国际碳价大幅波动。美国加州、区域温室气体减排行动（RGGI）、加拿大魁北克二级市场价格均下跌。2020年3月中旬欧盟碳价跌至15欧元左右，比疫情前下跌超过30%，创2018年11月以来的历史新低。瑞士将履约期限推迟4个月，3月和6月的拍卖结果宣告无效，与欧盟碳排放交易体系（EU-ETS）的连接也被推迟到9月。韩国碳市场将履约期限延长1个月，配额价格在5月下降后波动逐步加大。其后，受以欧盟为代表的国家和地区推出一系列突出绿色发展的疫情复苏计划的激励，国际碳价逐渐回升，主流碳市场价格已恢复至疫情前水平，近期欧洲碳价更是创出新高。此外，疫情将导致航空业未来排放量上升。2020年6月30日，国际民航组织同意在国际航空碳抵消和减排计划（CORSIA）前三年仅以2019年排放量为基准。
        
        全球碳市场发展面临的主要矛盾
        
        一是市场分割。《京都议定书》规定的三个机制均涉及不同国家和市场间的业务关系。不同市场在法律法规、配额分配方式、交易机制等方面存在差异。复杂的法律适用和地域管辖增加了市场主体的风险，跨国项目的审批认证存在较大障碍和较高成本。
        
        二是不确定风险。如国际公约的全面性和延续性问题产生碳泄露，美国退出《巴黎协定》，部分国家考虑征收碳边境调节税。
        
        三是碳价低迷。世界银行发布的《State and Trends of Carbon Pricing 2020》指出，尽管全球碳价不断上涨，但仍低于实现《巴黎协定》目标所需的价格。截至2020年3月末，全球碳价近一半低于10美元/吨，仅不到5％的碳价与实现《巴黎协定》的目标一致。
        
        四是碳金融创新能力不足。由于碳金融专业人才缺乏，部分政府尚未完全放开建设碳市场、对待碳金融产品的开发和创新比较谨慎等原因，全球碳金融产品服务创新仍滞后于需求。
        
        我国碳市场发展现状、问题及原因
        
        当前，积极应对气候变化已成全球共识，大部分主要经济体已明确提出碳达峰、碳中和目标。2020年，习近平总书记也提出了“2030年前实现碳达峰、2060年前实现碳中和”的新国家自主贡献目标。作为重要的市场化减排工具，我国碳市场发展基础坚实、潜力巨大，但由于全国统一的碳市场刚刚起步，还需针对政策框架不完善、金融化程度不足、碳市场作用发挥不充分等突出问题提出有效解决方案。
        
        碳市场整体政策框架尚不完善、国际地位较低
        
        截至2019年末，京、津、沪、渝、粤、鄂、深7省市试点碳市场配额累计成交量3.56亿吨，金额超过73亿元。受疫情影响，部分试点延迟了2020年的履约日期，但碳价未有明显波动。全国统一的碳排放权交易市场（火电行业）于2017年底正式建立，2021年1月1日首个履约周期正式启动。从国内看，全国统一的碳排放权交易市场相关制度位阶低，监管规则、统计制度、披露要求等细则尚不明确。此外，碳排放权资产的法律属性不明确、价值评估体系薄弱，阻碍了碳金融产品工具的推广与创新。从国际看，碳定价权被发达国家掌握，我国在碳定价和交易中处从属地位。欧元是现阶段碳交易计价结算的主要货币，我国碳市场仍处于产业链末端，话语权较小。
        
        碳市场金融化程度总体偏低
        
        试点地区和金融机构陆续开发了碳债券、碳远期、碳期权、碳基金、跨境碳资产回购、碳排放权抵质押融资等产品，但碳金融仍处于零星试点状态，区域发展不均衡，缺乏系统完善的碳金融市场，不能满足控排企业的碳资产管理需求，更不足以辐射和服务“一带一路”等相关区域的碳交易。此外，专业化投资者群体不发达，碳金融发展缺乏专业的长期资金支持。
        
        碳市场作用发挥不充分
        
        国际实践证明，碳金融市场发展高度依赖于碳排放控制强度和碳排放权现货交易市场的成熟度。与欧美将金融功能内置于碳市场不同，我国更多将碳金融定位为服务于碳减排的从属性市场工具，这也成为碳市场作用发挥不充分的重要原因。一是碳减排硬约束效力不足。由于担心限制碳排放会抑制经济增长、企业利润和居民就业，部分地方政府和市场主体控碳减排的内在动力严重不足。二是碳减排主要依靠行政手段。碳排放配额占全国CO2排放量的比重不到30%，碳交易量则更低，市场主体参与度不高。究其原因，一方面，受疫情影响，企业生产活动收缩、碳排放下降，控排企业对配额的需求低。另一方面，碳价低迷影响了碳配额的投资价值。由于过去年份各试点发放的配额剩余存量较大，碳市场存在过度供给，多数试点碳价偏低，控排企业和其他市场主体基于碳配额开展投融资活动的动力不足。
        
        推动我国碳金融市场加快发展的政策建议
        
        
        加快发展碳金融市场，既要夯实产业基础和现货市场，也要构建完善碳金融制度体系。
        
        第一，提高认识，强化约束，争取提前实现2030达峰目标。一方面，压实地方政府主体责任，继续强化碳排放硬约束。加强宣传教育和政策激励，提升地方政府和市场主体落实“十三五”碳排放控制目标的意识、决心和行动，争取提前达峰，并探索推动部分先进地区率先提出碳中和目标。研究建立碳排放总量控制制度，将总量控制目标细化分解至各行业、各地区，并强化考核。另一方面，培育交易活跃、全国统一的碳排放权市场，尽快推动正式开展交易。遵循适度从紧原则确定碳配额总额，确保形成合理碳价。在交易方式方面给予交易机构更大的灵活性，在严格监管的前提下研究探索碳排放权交易场所开展连续交易和集合竞价。引入中央对手方机制，建立碳定价中心，设立碳配额预留机制与碳市场平准基金，完善价格调控。
        
        第二，培育绿色低碳产业，提升碳金融市场需求。着力培育具有国际竞争优势的绿色低碳产业，发展碳捕集和封存等低碳技术，弥补高污染高能耗产业退出对经济增长和就业的负面影响，提升实体经济对包括碳金融在内的绿色金融的市场需求。将绿色发展理念融入疫情后的经济复苏计划，如新增投资尽可能向绿色项目倾斜，在救助计划中附带“减排提效”条件等。
        
        第三，完善法律法规和部门规章。在法律层面明确碳排放权的属性。建议在《民法典》执行过程中，对包括碳排放权在内的环境权益的法律属性及可否出质等进一步予以明确。在部门规章层面，建议制定碳金融市场监管和交易管理政策，统一市场监管、交易制度、法律责任、激励约束机制、会计及税收处理等相关内容。
        
        第四，提升碳金融市场的定价权威性和交易效率。适当放宽准入，鼓励相关金融机构和碳资产管理公司参与市场交易、创新产品工具。探索建立碳金融行业自律机制。培育中介机构和市场，鼓励发展融资类、投资类、保障类、信息咨询服务类中介机构。鼓励数字技术与碳金融深度融合，利用大数据、区块链、智能投顾等先进技术在客户筛选、投资决策、交易定价、投/贷后管理、信息披露、投资者教育等方面提供更多支持。
        `
    }
]