import React, { useContext } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import { Map, Marker } from 'react-amap';
import { tampCreateContext } from '@/utils/context';
import mapIcon from '../KonwImages/mapIcon.png';
import './ContactUs.scss';

export default function ContactUs(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;

    return (
        <div className="ContactUs">
            <HeadMenu Background={1} />

            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                    // setindex(destination.index);
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className="content2 section">
                                <div className={`view ${!global.isPc && 'md_view'}`}>
                                    <div className="title">{locales.ContactUs1}</div>
                                    <div className="content2View">
                                        <ul>
                                            <li>{locales.ContactUs2}</li>
                                            <li>chillmoon</li>
                                            <li>  </li>
                                            <li>{locales.ContactUs3}</li>
                                            <li>{locales.ContactUs4}</li>
                                            <li>
                                                <Map
                                                    amapkey={'788e08def03f95c670944fe2c78fa76f'}
                                                    plugins={['ToolBar']}
                                                    zoom={15}
                                                    center={{
                                                        longitude: 116.471507,
                                                        latitude: 39.913192,
                                                    }}
                                                >
                                                    <Marker position={{ longitude: 116.471507, latitude: 39.913192 }} icon={mapIcon} />
                                                </Map>
                                            </li>
                                            <a className="wxicon" href="/static/media/wxCode.538de11d.jpeg" target="_Blank" />

                                        </ul>
                                    </div>
                                    <div className="content2View">
                                        <ul>
                                            <li>{locales.ContactUs5}</li>
                                            <li>hanyue001@mohuoer.com</li>
                                            <li></li>
                                            <li>{locales.ContactUs6}</li>
                                            <li>{locales.ContactUs7} <br /> {locales.ContactUs8}   </li>
                                            <li>
                                                <Map
                                                    amapkey={'788e08def03f95c670944fe2c78fa76f'}
                                                    plugins={['ToolBar']}
                                                    zoom={15}
                                                    center={{
                                                        longitude: 121.638823,
                                                        latitude: 29.858288,
                                                    }}
                                                >
                                                    <Marker position={{ longitude: 121.638823, latitude: 29.858288 }} icon={mapIcon} />
                                                </Map>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Footer section />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}