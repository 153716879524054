import React, { useState, useContext, useRef, useEffect } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import { tampCreateContext } from '@/utils/context';
import './Recruit.scss';

export default function Recruit(props) {
    const [top, settop] = useState(200);
    const [recruitType, setrecruitType] = useState(3);
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;
    const topdata = useRef(null);
    useEffect(() => {

    }, [])
    // 技术研发
    const PostTypes1 = [
        {
            title: "初/中级 Java开发工程师",
            salary: "",
            age: "",
            record: "",
            responsibility: [
                '1、负责公司核心系统的设计与开发',
                '2、负责公司核心系统的需求分析、技术开发、架构设计，以及持续优化',
                '3、跨地域团队协作沟通，确保系统交付符合业务和部门的实际需求'
            ],
            postRequirements: [
                '1、统招本科学历计算机相关专业，有证券交易项目开发经验',
                '2、有Java开发经验，熟悉IO、多线程、异步处理和常用中间件产品，熟悉缓存、消息机制等；熟悉OOP，并熟练合理运用设计模式',
                '3、熟练使用Spring Boot、SpringMVC、Shiro、POI、mybatis等主流框架，有源码阅读经验更佳',
                '4、熟练掌握软件开发流程，具备良好的系统分析、设计能力',
                '5、责任心强，对技术有执着热情，有持续学习能力，抗压能力强者优先'
            ],
            office: "北京市朝阳区光华路甲8号和乔大厦C座1506",
            email: "zhangyamin@mohuoer.com"
        },
        {
            title: "证券量化交易系统c++开发",
            salary: "",
            age: "",
            record: "",
            responsibility: [
                '1、负责现有交易系统和数据平台的维护及开发新的功能',
                '2、量化交易系统实施及部署工作',
                '3、投资策略量化分析及金融衍生产品研究'
            ],
            postRequirements: [
                '1、计算机、通信、电子信息相关专业本科学历，熟悉金融（证券、期货）交易系统，有量化交易系统开发工作经验者优先；',
                '2、扎实的C++编程功底，3年以上 C++开发经验，对数据结构和算法及网络通讯协议、多线程、消息队列、设计模式等有深入理解和使用经验；',
                '3、至少对一种主流数据库有深入理解，包含但不限于Oracle/Redis/MongoDB/MySQL等； ',
                '4、具备良好的沟通表达能力和团队协作意识，有上进心和求知欲，善于学习新事物，对解决挑战性问题充满热情。'
            ],
            office: "北京市朝阳区光华路甲8号和乔大厦C座1506",
            email: "zhangyamin@mohuoer.com"
        },
        {
            title: "量化策略开发工程师",
            salary: "",
            age: "",
            record: "",
            responsibility: [
                '1、分析各类资产的特点及其投资方式，负责公募基金分析，同类产品竞品分析；',
                '2、运用资产配置相关概念，原理和方法进行量化策略的研究和开发；',
                '3、维护并优化现有资产配置相关模型和算法。'
            ],
            postRequirements: [
                '1、统计、金融、计算机、人工智能等相关专业本科及以上学历；',
                '2、优秀的编程基础，至少掌握一门面向对象编程语言JAVA或者Python；',
                '3、具备良好的数理基础及算法分析能力，有资产配置等相关金融基础知识者优先，有券商、基金公司量化策略相关工作经验优先。；',
                '4、独立学习和工作能力强，能够相对独立研究工作相关各类课题；'
            ],
            office: "北京市朝阳区光华路甲8号和乔大厦C座1506",
            email: "zhangyamin@mohuoer.com"
        }
    ];
    // 产品设计
    const PostTypes2 = [];
    // 市场营销
    const PostTypes3 = [
        {
            title: "金融分析师",
            salary: "",
            age: "",
            record: "",
            responsibility: [
                '1、负责对中国证券市场相关数据收集、整理',
                '2、中国市场基金及理财产品的研究和分析',
                '3、证券市场相关投资策略和资产配置方案的研究和分析 ',
                '4、金融投资客户需求分析，投资顾问产品功能定义、规划和设计'
            ],
            postRequirements: [
                '1、本科及以上学历，数学、金融、统计相关专业，熟练使用Python；',
                '2、对金融市场、投资理财及量化分析有浓厚的兴趣，具有快速学习的能力；',
                '3、有较强的沟通能力、逻辑能力和产品设计能力，对数据敏感，具备较强数据分析加工能力； ',
                '4、有在银行／券商／基金／保险等机构投资理财等相关岗位或互联网金融行业经验优先；',
                '5、擅长进行市场宏观分析、竞品分析、行业分析。'
            ],
            office: "北京市朝阳区光华路甲8号和乔大厦C座1506",
            email: "zhangyamin@mohuoer.com"
        },
        {
            title: "基金销售总监",
            salary: "",
            age: "",
            record: "",
            responsibility: [
                '1、根据公司营销战略及目标，制定渠道开发和客户开发的计划并完成实施，达成基金销售业绩及销售渠道的维护与管理；',
                '2、负责与银行、证券、保险、信托以及第三方理财机构或个人建立合作关系、持续服务；',
                '3、负责公司的金融产品在各大金融机构的推广与宣传；',
                '4、收集并统计市场信息和客户建议，并及时整理信息，为公司提供合理的建议。',
                '5、负责收集金融市场信息和客户建议，向客户传递公司理财产品和服务信息。'
            ],
            postRequirements: [
                '1、本科及以上学历，金融、经济、管理、营销类等相关专业，具备基金从业资格； ',
                '2、有成熟的渠道资源，能够独立地拓展基金营销渠道、参与基金产品设计、完成基金募集工作；',
                '3、具备丰富的高净值客户及基金信托推广渠道资源，以及较强的市场开拓能力，在银行、信托、基金等金融机构具备广泛高端的人脉资源和良好的业内人际关系；',
                '4、具有良好的职业道德及较强的表达沟通能力和人际交往能力，能承受较强的工作压力，并拥有较强的多任务处理能力；'
            ],
            office: "北京市朝阳区光华路甲8号和乔大厦C座1506",
            email: "zhangyamin@mohuoer.com"
        },
        {
            title: "私募券商销售专员",
            salary: "",
            age: "",
            record: "",
            responsibility: [
                '1.负责向金融机构客户销售公司技术产品，签约、收款和维护工作，以达成销售指标；',
                '2.负责金融机构客户的业务接洽、咨询及关系维护，挖掘潜力需求，保持并发展合作关系；',
                '3.实时反馈市场信息，了解行业竞争情况，并提出建议性意见，为公司制定销售策略提供手资料有行业灵敏性；',
                '4.积极反馈客户使用中的各类问题，提出合理建议与意见，促使流程与服务的优质化。'
            ],
            postRequirements: [
                '1. 全日制统招本科及以上学历，金融财经营销类相关专业；',
                '2. 一年以上面向金融机构（公私募基金保险资管、券商）销售或服务经验；',
                '3.形象气质佳，具有较强的学习能力和良好的沟通能力，语言表达能力、逻辑思维能力；',
                '4.具有坚持、不服输、勇于挑战和乐观的优秀意志品质以及良好的价值观；',
                '5.销售职业和金融相关行业作为职业生涯规划考虑的；',
                '6.能够有效识别、挖掘、引导客户需求；',
                '7.具有良好的团队合作精神和服务意识；热爱营销工作，抗压能力强。'
            ],
            office: "北京市朝阳区光华路甲8号和乔大厦C座1506",
            email: "zhangyamin@mohuoer.com"
        },
    ];
    // 产品运营
    const PostTypes4 = [];
    // 客户服务
    const PostTypes5 = [];
    // 其他职位
    const PostTypes6 = [];



    return (
        <div className="Recruit">
            <HeadMenu Background={1} />
            {
                global.isPc &&
                // <div ref={topdata} className={`typeViewBox ${top !== 200 ? 'typeViewBoxZindex typeViewBoxB' : 'typeViewBoxT'}`}>
                <div ref={topdata} className={`typeViewBox typeViewBoxZindexON`}>
                    <ul className="typeView">
                        <li onClick={() => setrecruitType(1)} className={`${recruitType === 1 && 'recruitTypeIn'}`}>{locales.Recruit1}</li>
                        <li onClick={() => setrecruitType(2)} className={`${recruitType === 2 && 'recruitTypeIn'}`}>{locales.Recruit2}</li>
                        <li onClick={() => setrecruitType(3)} className={`${recruitType === 3 && 'recruitTypeIn'}`}>{locales.Recruit3}</li>
                        <li onClick={() => setrecruitType(4)} className={`${recruitType === 4 && 'recruitTypeIn'}`}>{locales.Recruit4}</li>
                        <li onClick={() => setrecruitType(5)} className={`${recruitType === 5 && 'recruitTypeIn'}`}>{locales.Recruit5}</li>
                        <li onClick={() => setrecruitType(6)} className={`${recruitType === 6 && 'recruitTypeIn'}`}>{locales.Recruit6}</li>
                    </ul>
                    <div className="emailView">
                        <div>{locales.Recruit7}</div>
                        <div>zhangyamin@mohuoer.com</div>
                        <div>{locales.Recruit8}</div>
                        <div>{locales.Recruit9}</div>
                    </div>
                </div>
            }

            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                    if (global.isPc) {
                        if (destination.index === 1) {
                            topdata.current.classList.remove('typeViewBoxZindexON');
                            topdata.current.classList.remove('typeViewBoxBON');
                            topdata.current.classList.add('typeViewBoxZindex');
                            setTimeout(() => {
                                topdata.current.classList.add('typeViewBoxB');
                            }, 50);
                            settop(-100);
                        } else {
                            settop(200);
                            topdata.current.classList.remove('typeViewBoxZindex');
                            topdata.current.classList.remove('typeViewBoxB');
                            topdata.current.classList.add('typeViewBoxBON');
                            setTimeout(() => {
                                topdata.current.classList.add('typeViewBoxZindexON');
                            }, 300);
                        }
                    }
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className="content2 section">
                                <div className={`content2View ${!global.isPc && 'md_content2View'}`}>
                                    <div className="recruitType">
                                        {
                                            !global.isPc &&
                                            <>
                                                <ul className="typeView">
                                                    <li onClick={() => setrecruitType(1)} className={`${recruitType === 1 && 'recruitTypeIn'}`}>{locales.Recruit1}</li>
                                                    <li onClick={() => setrecruitType(2)} className={`${recruitType === 2 && 'recruitTypeIn'}`}>{locales.Recruit2}</li>
                                                    <li onClick={() => setrecruitType(3)} className={`${recruitType === 3 && 'recruitTypeIn'}`}>{locales.Recruit3}</li>
                                                    <li onClick={() => setrecruitType(4)} className={`${recruitType === 4 && 'recruitTypeIn'}`}>{locales.Recruit4}</li>
                                                    <li onClick={() => setrecruitType(5)} className={`${recruitType === 5 && 'recruitTypeIn'}`}>{locales.Recruit5}</li>
                                                    <li onClick={() => setrecruitType(6)} className={`${recruitType === 6 && 'recruitTypeIn'}`}>{locales.Recruit6}</li>
                                                </ul>
                                                <div className="emailView">
                                                    <div>{locales.Recruit7}</div>
                                                    <div>zhangyamin@mohuoer.com</div>
                                                    <div>{locales.Recruit8}</div>
                                                    <div>{locales.Recruit9}</div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="recruitList">
                                        {
                                            recruitType === 1 &&
                                            <div className="recruitListView animated fadeIn">
                                                {
                                                    PostTypes1.length !== 0 ? PostTypes1.map((item, index) => {
                                                        return (
                                                            <div className="recruitView" key={item + index}>
                                                                <div className="recruitViewTitles">
                                                                    <span>{item.title}</span>
                                                                    <span>{item.salary && item.salary}</span>
                                                                    <span>{item.age && item.age}</span>
                                                                    <span>{item.record && ' | '}</span>
                                                                    <span>{item.record && item.record}</span>
                                                                </div>
                                                                <div className="recruitViewDescribe">职业描述</div>
                                                                <div className="recruitViewDuty">岗位职责:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.responsibility.map(itemChild1 => {
                                                                            return (
                                                                                <p key={itemChild1}>{itemChild1}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDuty">岗位要求:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.postRequirements.map(itemChild2 => {
                                                                            return (
                                                                                <p key={itemChild2}>{itemChild2}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDescribe">办公地点和简历发送邮箱</div>
                                                                <div className="recruitViewDutyCon">
                                                                    <p>{item.office}</p>
                                                                    <p>{item.email}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                        :
                                                        <div className="recruitListView animated fadeIn">{locales.Recruit10}</div>
                                                }
                                            </div>
                                        }
                                        {
                                            recruitType === 2 &&
                                            <div className="recruitListView animated fadeIn">
                                                {
                                                    PostTypes2.length !== 0 ? PostTypes2.map((item, index) => {
                                                        return (
                                                            <div className="recruitView" key={item + index}>
                                                                <div className="recruitViewTitles">
                                                                    <span>{item.title}</span>
                                                                    <span>{item.salary && item.salary}</span>
                                                                    <span>{item.age && item.age}</span>
                                                                    <span>{item.record && ' | '}</span>
                                                                    <span>{item.record && item.record}</span>
                                                                </div>
                                                                <div className="recruitViewDescribe">职业描述</div>
                                                                <div className="recruitViewDuty">岗位职责:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.responsibility.map(itemChild1 => {
                                                                            return (
                                                                                <p key={itemChild1}>{itemChild1}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDuty">岗位要求:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.postRequirements.map(itemChild2 => {
                                                                            return (
                                                                                <p key={itemChild2}>{itemChild2}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDescribe">办公地点和简历发送邮箱</div>
                                                                <div className="recruitViewDutyCon">
                                                                    <p>{item.office}</p>
                                                                    <p>{item.email}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                        :
                                                        <div className="recruitListView animated fadeIn">{locales.Recruit10}</div>
                                                }
                                            </div>
                                        }
                                        {
                                            recruitType === 3 &&
                                            <div className="recruitListView animated fadeIn">
                                                {
                                                    PostTypes3.length !== 0 ? PostTypes3.map((item, index) => {
                                                        return (
                                                            <div className="recruitView" key={item + index}>
                                                                <div className="recruitViewTitles">
                                                                    <span>{item.title}</span>
                                                                    <span>{item.salary && item.salary}</span>
                                                                    <span>{item.age && item.age}</span>
                                                                    <span>{item.record && ' | '}</span>
                                                                    <span>{item.record && item.record}</span>
                                                                </div>
                                                                <div className="recruitViewDescribe">职业描述</div>
                                                                <div className="recruitViewDuty">岗位职责:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.responsibility.map(itemChild1 => {
                                                                            return (
                                                                                <p key={itemChild1}>{itemChild1}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDuty">岗位要求:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.postRequirements.map(itemChild2 => {
                                                                            return (
                                                                                <p key={itemChild2}>{itemChild2}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDescribe">办公地点和简历发送邮箱</div>
                                                                <div className="recruitViewDutyCon">
                                                                    <p>{item.office}</p>
                                                                    <p>{item.email}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                        :
                                                        <div className="recruitListView animated fadeIn">{locales.Recruit10}</div>
                                                }
                                            </div>
                                        }
                                        {
                                            recruitType === 4 &&
                                            <div className="recruitListView animated fadeIn">
                                                {
                                                    PostTypes4.length !== 0 ? PostTypes4.map((item, index) => {
                                                        return (
                                                            <div className="recruitView" key={item + index}>
                                                                <div className="recruitViewTitles">
                                                                    <span>{item.title}</span>
                                                                    <span>{item.salary && item.salary}</span>
                                                                    <span>{item.age && item.age}</span>
                                                                    <span>{item.record && ' | '}</span>
                                                                    <span>{item.record && item.record}</span>
                                                                </div>
                                                                <div className="recruitViewDescribe">职业描述</div>
                                                                <div className="recruitViewDuty">岗位职责:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.responsibility.map(itemChild1 => {
                                                                            return (
                                                                                <p key={itemChild1}>{itemChild1}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDuty">岗位要求:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.postRequirements.map(itemChild2 => {
                                                                            return (
                                                                                <p key={itemChild2}>{itemChild2}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDescribe">办公地点和简历发送邮箱</div>
                                                                <div className="recruitViewDutyCon">
                                                                    <p>{item.office}</p>
                                                                    <p>{item.email}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                        :
                                                        <div className="recruitListView animated fadeIn">{locales.Recruit10}</div>
                                                }
                                            </div>
                                        }
                                        {
                                            recruitType === 5 &&
                                            <div className="recruitListView animated fadeIn">
                                                {
                                                    PostTypes5.length !== 0 ? PostTypes5.map((item, index) => {
                                                        return (
                                                            <div className="recruitView" key={item + index}>
                                                                <div className="recruitViewTitles">
                                                                    <span>{item.title}</span>
                                                                    <span>{item.salary && item.salary}</span>
                                                                    <span>{item.age && item.age}</span>
                                                                    <span>{item.record && ' | '}</span>
                                                                    <span>{item.record && item.record}</span>
                                                                </div>
                                                                <div className="recruitViewDescribe">职业描述</div>
                                                                <div className="recruitViewDuty">岗位职责:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.responsibility.map(itemChild1 => {
                                                                            return (
                                                                                <p key={itemChild1}>{itemChild1}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDuty">岗位要求:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.postRequirements.map(itemChild2 => {
                                                                            return (
                                                                                <p key={itemChild2}>{itemChild2}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDescribe">办公地点和简历发送邮箱</div>
                                                                <div className="recruitViewDutyCon">
                                                                    <p>{item.office}</p>
                                                                    <p>{item.email}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                        :
                                                        <div className="recruitListView animated fadeIn">{locales.Recruit10}</div>
                                                }
                                            </div>
                                        }
                                        {
                                            recruitType === 6 &&
                                            <div className="recruitListView animated fadeIn">
                                                {
                                                    PostTypes6.length !== 0 ? PostTypes6.map((item, index) => {
                                                        return (
                                                            <div className="recruitView" key={item + index}>
                                                                <div className="recruitViewTitles">
                                                                    <span>{item.title}</span>
                                                                    <span>{item.salary && item.salary}</span>
                                                                    <span>{item.age && item.age}</span>
                                                                    <span>{item.record && ' | '}</span>
                                                                    <span>{item.record && item.record}</span>
                                                                </div>
                                                                <div className="recruitViewDescribe">职业描述</div>
                                                                <div className="recruitViewDuty">岗位职责:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.responsibility.map(itemChild1 => {
                                                                            return (
                                                                                <p key={itemChild1}>{itemChild1}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDuty">岗位要求:</div>
                                                                <div className="recruitViewDutyCon">
                                                                    {
                                                                        item.postRequirements.map(itemChild2 => {
                                                                            return (
                                                                                <p key={itemChild2}>{itemChild2}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="recruitViewDescribe">办公地点和简历发送邮箱</div>
                                                                <div className="recruitViewDutyCon">
                                                                    <p>{item.office}</p>
                                                                    <p>{item.email}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                        :
                                                        <div className="recruitListView animated fadeIn">{locales.Recruit10}</div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Footer section />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}