import React, { useState, useContext } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import { tampCreateContext } from '@/utils/context';
import SwiperCore, { Navigation, Scrollbar, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper-bundle.min.css';
import './Know.scss';

SwiperCore.use([Navigation, Scrollbar, Controller]);

export default function Know(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;

    const [activeIndex, setactiveIndex] = useState(5);
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);

    return (
        <div className="Know">
            <HeadMenu Background={1} />

            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                // scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                    // setindex(destination.index);
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className="content content2 section">
                                <div className={`content2View ${!global.isPc && 'md_content2View'}`} >
                                    <div className="aboutUs">
                                        <div className="aboutUsPic"></div>
                                        <div className="aboutUsContent">
                                            <div className="aboutUsContentView">
                                                <div className="aboutUsContentTitle">{locales.Know1}</div>
                                                <div className="aboutUsContentText">{locales.Know2}</div>
                                                <div className="aboutUsContentText aboutUsContentTextCle">{locales.Know3}</div>
                                                <div className="aboutUsContentText">{locales.Know4}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="devHistory section">
                                <div className={`devHistoryView ${!global.isPc && 'md_devHistoryView'}`}>
                                    <div className="devHistorySwiper">
                                        <Swiper
                                            direction='vertical'
                                            spaceBetween={0}
                                            slidesPerView={5}
                                            initialSlide={6}   // 默认第几条
                                            centeredSlides  // 居中
                                            navigation
                                            noSwiping={true}
                                            scrollbar={{ draggable: true }}
                                            onSwiper={setFirstSwiper}
                                            controller={{ control: secondSwiper }}
                                            className={`devHistorySwiperView ${!global.isPc && 'md_devHistorySwiperView'}`}
                                            onSlideChange={(swiper) => {
                                                setactiveIndex(swiper.activeIndex)
                                            }}
                                        // onTouchStart={(swiper, e) => {
                                        //     // e.preventDefault();
                                        //     e.stopPropagation();
                                        // }}
                                        // onTouchMove={(swiper, e) => {
                                        //     // e.preventDefault();
                                        //     e.stopPropagation();
                                        // }}
                                        // onTouchMoveOpposite={(swiper, e) => {
                                        //     e.stopPropagation();
                                        // }}
                                        // onSlideMove={(swiper, e) => {
                                        //     e.stopPropagation();
                                        // }}

                                        >
                                            <h3 className="devHistorySwiperTtile">{locales.Know5}</h3>
                                            {/* <SwiperSlide>
                                                <div className={` HistorySwiper swiper-no-swiping ${activeIndex === 0 ? 'electSwiperSlide' : ''}`}>
                                                    2015{locales.Know6}
                                                    </div>
                                            </SwiperSlide> */}
                                            <SwiperSlide>
                                                <div className={` HistorySwiper swiper-no-swiping ${activeIndex === 0 ? 'electSwiperSlide' : ''}`} >
                                                    2016{locales.Know6}
                                                    </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className={` HistorySwiper swiper-no-swiping ${activeIndex === 1 ? 'electSwiperSlide' : ''}`}>
                                                    2017{locales.Know6}
                                                    </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className={` HistorySwiper swiper-no-swiping ${activeIndex === 2 ? 'electSwiperSlide' : ''}`}>
                                                    2018{locales.Know6}
                                                    </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className={` HistorySwiper swiper-no-swiping ${activeIndex === 3 ? 'electSwiperSlide' : ''}`}>
                                                    2019{locales.Know6}
                                                    </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className={` HistorySwiper swiper-no-swiping ${activeIndex === 4 ? 'electSwiperSlide' : ''}`}>
                                                    2020{locales.Know6}
                                                    </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className={` HistorySwiper swiper-no-swiping ${activeIndex === 5 ? 'electSwiperSlide' : ''}`}>
                                                    2021{locales.Know6}
                                                    </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <Swiper
                                        direction='vertical'
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        centeredSlides  // 居中
                                        onSwiper={setSecondSwiper}
                                        controller={{ control: firstSwiper }}
                                        // onTouchStart={(swiper, e) => {
                                        //     // e.preventDefault();
                                        //     e.stopPropagation();
                                        // }}
                                        noSwiping={true}
                                        className={`devHistorySwiperViewCon ${!global.isPc && 'md_devHistorySwiperViewCon'}`}
                                    >
                                        {/* <SwiperSlide>
                                            <div className="HistorySwiper swiper-no-swiping">
                                                <div className="HistorySwiperTitle">{locales.Know7}</div>
                                                <div className="HistorySwiperCon">{locales.Know8}</div>
                                            </div>
                                        </SwiperSlide> */}
                                        <SwiperSlide>
                                            <div className="HistorySwiper swiper-no-swiping">
                                                <div className="HistorySwiperTitle">{locales.Know9}</div>
                                                <div className="HistorySwiperCon">{locales.Know10}</div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="HistorySwiper swiper-no-swiping">
                                                <div className="HistorySwiperTitle">{locales.Know11}</div>
                                                <div className="HistorySwiperCon">{locales.Know12}</div>
                                                {/* <div className="HistorySwiperPic"></div> */}
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="HistorySwiper swiper-no-swiping">
                                                <div className="HistorySwiperTitle">{locales.Know13}</div>
                                                <div className="HistorySwiperCon">{locales.Know14}</div>
                                                <div className="HistorySwiperCon">{locales.Know15}</div>
                                                {/* <div className="HistorySwiperPic"></div> */}
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="HistorySwiper swiper-no-swiping">
                                                <div className="HistorySwiperTitle">{locales.Know16}</div>
                                                <div className="HistorySwiperCon">{locales.Know17}</div>
                                                {/* <div className="HistorySwiperPic HistorySwiperPic2"></div> */}
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="HistorySwiper swiper-no-swiping">
                                                <div className="HistorySwiperTitle">{locales.Know18}</div>
                                                <div className="HistorySwiperCon">{locales.Know19}</div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="HistorySwiper swiper-no-swiping">
                                                <div className="HistorySwiperTitle">{'无代码量化交易系统上线'}</div>
                                                <div className="HistorySwiperCon">{'2021年，为交易员和机构打造无代码量化交易系统上线'}</div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>

                            <div className="myAdvantage section">
                                <div className={`${!global.isPc && 'md_myAdvantage'}`}>
                                    <h3 className="myAdvantageTitle">{locales.Know20}</h3>
                                    <ul className="myAdvantageView">
                                        <li className="myAdvantageList">
                                            <div className="myAdvantageListPic"></div>
                                            <div className="myAdvantageListCn">数据驱动</div>
                                            <div className="myAdvantageListEn">Data—driven Analytics</div>
                                        </li>
                                        <li className="myAdvantageList">
                                            <div className="myAdvantageListPic"></div>
                                            <div className="myAdvantageListCn">可行性分析</div>
                                            <div className="myAdvantageListEn">Actionable Insight</div>
                                        </li>
                                        <li className="myAdvantageList">
                                            <div className="myAdvantageListPic"></div>
                                            <div className="myAdvantageListCn">实时追踪</div>
                                            <div className="myAdvantageListEn">Real—time Tracking</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="section">
                                <div className={`myVision  ${!global.isPc && 'md_myVision'}`}>
                                    <h3 className="myVisionTitle">{locales.Know21}</h3>
                                    <div className="myVisionGuide">{locales.Know22}</div>
                                    <ul className="myVisionView">
                                        <li className="myVisionList">
                                            <div className="myVisionListPicView">
                                                <div className="myVisionListPic"></div>
                                            </div>
                                            <div className="myVisionListTitle">{locales.Know23}</div>
                                            <div className="myVisionListCon">{locales.Know24}</div>
                                            <div className="myVisionListCon">{locales.Know25}</div>
                                        </li>
                                        <li className="myVisionList">
                                            <div className="myVisionListPicView">
                                                <div className="myVisionListPic"></div>
                                            </div>
                                            <div className="myVisionListTitle">{locales.Know26}</div>
                                            <div className="myVisionListCon">{locales.Know27}</div>
                                            <div className="myVisionListCon">{locales.Know28}</div>
                                        </li>
                                        <li className="myVisionList">
                                            <div className="myVisionListPicView">
                                                <div className="myVisionListPic"></div>
                                            </div>
                                            <div className="myVisionListTitle">{locales.Know29}</div>
                                            <div className="myVisionListCon">{locales.Know30}</div>
                                            <div className="myVisionListCon">{locales.Know31}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <Footer section />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}