import React from 'react';
import ReactDOM from 'react-dom';
import App from '@/App';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
);
