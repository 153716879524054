import React, { useContext } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import { tampCreateContext } from '@/utils/context';
import './Technology.scss';

export default function Technology(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;

    return (
        <div className="Technology">
            <HeadMenu Background={1} />

            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className={`content1 section ${!global.isPc && 'md_content1'}`}>
                                <div className={`tampInfos`}>
                                    <div className="tampInfosViePic"></div>
                                    <div className="tampInfosViewText">
                                        <div className="tampInfosViewTextBox">
                                            <div className="tampInfosBoxTitle">
                                                {locales.Technology1}
                                            </div>
                                            <div className="tampInfosBoxText">
                                                {locales.Technology1_1}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content2 section">
                                <div className={`content2View ${!global.isPc && 'md_content2View'}`}>
                                    <div className="Solution">
                                        <div className="SolutionL">
                                            <div>{locales.Technology2}</div>
                                            <div>{locales.Technology3}</div>
                                            <div>{locales.Technology4}</div>
                                        </div>
                                        <div className="SolutionR">
                                            <div className="video">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="investmentView section">
                                <div className="title">{locales.Technology5}</div>
                                <div className="title">{locales.Technology6}</div>
                                <ul>
                                    <li>
                                        <div className="investmentPci"></div>
                                        <div className="investmentTitle">{locales.Technology7}</div>
                                        <div className="investmentText">{locales.Technology8}</div>
                                    </li>
                                    <li>
                                        <div className="investmentPci"></div>
                                        <div className="investmentTitle">{locales.Technology9}</div>
                                        <div className="investmentText">{locales.Technology10}</div>
                                    </li>
                                    <li>
                                        <div className="investmentPci"></div>
                                        <div className="investmentTitle">{locales.Technology11}</div>
                                        <div className="investmentText">{locales.Technology12}</div>
                                    </li>
                                </ul>
                            </div>
                            <Footer section />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}