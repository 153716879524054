import React from 'react';
import { Row, Col } from 'antd'
import './Home.scss';

const botItem = (txt, i) => {
  return <Col key={i} span={8} className='botItem'>
    <span className='botText'>{txt.title}</span>
    <p className='botSub'>{txt.sub}</p>
  </Col>
}

const Banner2 = () => {
  const botText = [
    { title: '试用中心', sub: '数十种交易策略免费使用' },
    { title: '新人福利', sub: '自动下单托管0.99元体验' },
    { title: '注册交易员专享', sub: '模拟实盘大赛享万元奖金' },
  ]
  return <div className="banner2">
    <div className='banner2Title'>
      <p style={{ fontSize: '36px' }}>无代码量化交易OS特惠来袭</p>
      <p style={{ fontSize: '21px', fontWeight: '100' }}>新用户体验0.99起，更有万元大赛奖金等你拿</p>
      <div className='regBtn'>
        <a href="https://simx.cfirstquant.com/login" target="_Blank" rel="noreferrer" style={{ color: 'white' }}> 立即登录</a>
      </div>
    </div>
    <div>
      <Row>
        {botText.map((txt, i) => botItem(txt, i))}
      </Row>
    </div>
  </div>

}

export default Banner2;