import React, { useContext } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import { tampCreateContext } from '@/utils/context';
import './Join.scss';


export default function Join(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;

    return (
        <div className="Join">
            <HeadMenu Background={1} />

            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                    // setindex(destination.index);
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className={`content1 section `}>
                                <div className={`content1View ${!global.isPc && 'md_content1'}`}>
                                    <h3 className="environmentTitle">
                                        {locales.Join1}
                                    </h3>
                                    <ul className="environmentUl">
                                        <li className="environmentLi"></li>
                                        <li className="environmentLi"></li>
                                        <li className="environmentLi"></li>
                                        <li className="environmentLi"></li>
                                    </ul>
                                    <ul className="environmentUl">
                                        <li className="environmentLi"></li>
                                        <li className="environmentLi"></li>
                                        <li className="environmentLi"></li>
                                        <li className="environmentLi"></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content2 section">
                                <div className={`content2View ${!global.isPc && 'md_welfarsView'}`}>
                                    <div className="title">{locales.Join2}</div>
                                    <div className="text">{locales.Join3}</div>
                                    <div className="text">{locales.Join4}</div>
                                    <div className="welfarsView">
                                        <ul>
                                            <li>
                                                <div className="welfarsPic"></div>
                                                <div className="welfarsTitle">{locales.Join5}</div>
                                                <div className="welfarsText">{locales.Join6}</div>
                                            </li>
                                            <li>
                                                <div className="welfarsPic"></div>
                                                <div className="welfarsTitle">{locales.Join7}</div>
                                                <div className="welfarsText">{locales.Join8}</div>
                                            </li>
                                            <li>
                                                <div className="welfarsPic"></div>
                                                <div className="welfarsTitle">{locales.Join9}</div>
                                                <div className="welfarsText">{locales.Join10}</div>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <div className="welfarsPic"></div>
                                                <div className="welfarsTitle">{locales.Join11}</div>
                                                <div className="welfarsText">{locales.Join12}</div>
                                            </li>
                                            <li>
                                                <div className="welfarsPic"></div>
                                                <div className="welfarsTitle">{locales.Join13}</div>
                                                <div className="welfarsText">{locales.Join14}</div>
                                            </li>
                                            <li>
                                                <div className="welfarsPic"></div>
                                                <div className="welfarsTitle">{locales.Join15}</div>
                                                <div className="welfarsText">{locales.Join16}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Footer section />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}