import React, { useState, useContext, useEffect } from 'react';
import { routeConfig, routeConfig_EN } from '@/router/route.config';
import { withRouter, Link } from 'react-router-dom';
import { tampCreateContext } from '@/utils/context';
import logoCN from "@/images/logoCN.svg";
import logoEN from "@/images/logoEN.svg";
// import police from '@/images/police.png';
import './Footer.scss';


function Footer(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;
    const [menusChild, setmenusChild] = useState({});
    const [routeConfigData, setrouteConfigData] = useState(
        store.language === 'en'
            ?
            [...routeConfig_EN, { title: 'Contact Us', path: 'past', childRoutes: [{ title: '微信:13269030756', path: 'past' }] }]
            :
            [...routeConfig, { title: '联系我们', path: 'past', childRoutes: [{ title: '微信:13269030756', path: 'past' }] }]

    );

    useEffect(() => {
        if (store.language === 'en') {
            setrouteConfigData([...routeConfig_EN, { title: 'Contact Us', path: 'past', childRoutes: [{ title: '微信:13269030756', path: 'past' }] }]);
        } else {
            setrouteConfigData([...routeConfig, { title: '联系我们', path: 'past', childRoutes: [{ title: '微信:13269030756', path: 'past' }] }]);
        }
    }, [store.language])

    function isPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone",
            "SymbianOS", "Windows Phone",
            "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    }

    function _mdHandleClick(path, index) {
        if (menusChild[index]) {
            return setmenusChild({ ...menusChild, [index]: false });
        } else {
            return setmenusChild({ ...menusChild, [index]: path });
        }
    }
    function _mdHandleClicList(path) {
        // console.log(path);
        if (path === 'past') {
            return;
        }
        props.history.push(path);
    }

    return (
        <>
            {
                isPC() ?
                    <div className={`Footer ${props.section && 'section fp-auto-height'}`}>
                        <div className="FooterTop">
                            <img src={store.language === 'en' ? logoEN : logoCN} className="FooterTopL" alt=""></img>

                            <div className="FooterTopC">
                                <ul>
                                    <li><Link to="/">{locales.Footer1}</Link></li>
                                    <li><Link to="/Home/TechnicalQuota">{locales.Footer2}</Link></li>
                                    <li><Link to="/Home/Technology">{locales.Footer3}</Link></li>
                                    <li><Link to="/Home/Service">{locales.Footer4}</Link></li>
                                    <li><Link to="/Home/Privacy">{'隐私政策'}</Link></li>
                                </ul>
                                <ul>
                                    <li>{locales.Footer5}</li>
                                    <li><Link to="/News/CompanyNews">{locales.Footer6}</Link></li>
                                    <li><Link to="/News/IndustryConsultation">{locales.Footer7}</Link></li>
                                </ul>
                                <ul>
                                    <li><Link to="/TAMP/">{locales.Footer8}</Link></li>
                                    {/* <li>投资者</li>
                                    <li>基金管理人</li>
                                    <li>机构/个人投资者</li> */}
                                </ul>
                                <ul>
                                    <li><Link to="/Know/">{locales.Footer9}</Link></li>
                                    {/* <li>公司简介</li> */}
                                    <li><Link to="/Know/HonorQualification">{locales.Footer10}</Link></li>
                                    {/* <li>法人简介</li> */}
                                    <li><Link to="/Know/ContactUs">{locales.Footer11}</Link></li>
                                </ul>
                                <ul>
                                    <li><Link to="/Join/">{locales.Footer12}</Link></li>
                                    {/* <li><Link to="/Join/SalaryBenefits">薪酬福利</Link></li> */}
                                    <li><Link to="/Join/CorporateCulture">{locales.Footer13}</Link></li>
                                    <li><Link to="/Join/Recruit">{locales.Footer14}</Link></li>
                                </ul>
                            </div>
                            <div className="FooterTopR">
                                <p className="FooterTopRTitle">{locales.Footer15}</p>
                                <div className="FooterTopRCodeView">
                                    <div className="FooterTopRCode">
                                        <div></div>
                                        <p>{locales.Footer16}</p>
                                    </div>
                                    {/* <div className="FooterTopRCode">
                                        <div></div>
                                        <p>{locales.Footer17}</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="FooterBottom">
                            <p>
                                <a href="https://beian.miit.gov.cn" target="_Blank">
                                    COPYRIGHT © 2021 程创(北京)科技有限公司 京ICP备 17035991号-3  |京公网安备
                                </a>
                            </p>
                            {/* <p><a href="https://beian.miit.gov.cn" target="_Blank">COPYRIGHT © 2019 一量科技 京ICP 17035991号-1  京ICP备17035991号-1|京公网安备</a></p> */}
                        </div>
                    </div>
                    :
                    // <>
                    <div className="md_Footer section">
                        <div className="md_menuLisUl">
                            {
                                routeConfigData.map((item, index) => {
                                    return (
                                        <div key={item + index} className={`${menusChild[index] && 'md_menuLisLiOpen'}`} >
                                            <div
                                                className={`md_menuLisLi`}
                                                onClick={() => _mdHandleClick(item.path, index)}
                                            >
                                                {item.title}
                                                {
                                                    item.childRoutes.map((childItem, childIndex) => {
                                                        return (
                                                            <div
                                                                className={`md_menuLisLiItem`}
                                                                key={childItem + childIndex}
                                                                onClick={() => _mdHandleClicList(childItem.path)}
                                                            >
                                                                {childItem.title}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="FooterBottom">
                            {/* <p>COPYRIGHT © 2016 程创(北京)科技有限公司 京ICP 17035991号-3  </p>
                            <p>京ICP备17035991号-3|京公网安备</p> */}
                            <p><a href="https://beian.miit.gov.cn" target="_Blank">COPYRIGHT © 2021 程创(北京)科技有限公司</a></p>
                            <p><a href="https://beian.miit.gov.cn" target="_Blank">京ICP备17035991号-3|京公网安备</a></p>
                        </div>
                    </div>
                // </>
            }
        </>
    )
}
export default withRouter(Footer);

