import React, { useEffect, useContext } from 'react';
import Footer from '@/component/Footer/Footer';
import { Input } from 'antd';
import { tampCreateContext } from '@/utils/context';
import './NewsContent.scss';
// import servicListsPic1 from '../../Homes/HomeImages/content3ConPic2.png';
import servicListsPic1 from '../NewssImages/IndustryConsultationPic2.jpeg';
import servicListsPic2 from '../NewssImages/IndustryConsultationPic3.png';
import servicListsPic3 from '../NewssImages/IndustryConsultationPic4.png';
import servicListsPic4 from '../NewssImages/IndustryConsultationPic5.png';
import servicListsPic5 from '../NewssImages/IndustryConsultationPic6.webp';
import servicListsPic6 from '../NewssImages/IndustryConsultationPic7.png';
import { servicLists, versionLists } from '@/utils/versions';

const { TextArea } = Input;

export default function NewsContent(props) {
    console.log(props.location.state.name);
    const index = props.location.state.name - 1;
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;

    useEffect(() => {
    }, [])
    function _goBack() {
        props.history.goBack()
    }

    return (
        <div className={`NewsContent ${!global.isPc && 'md_NewsContent'}`}>
            <div className="content">
                <div className="goBack" onClick={_goBack}>{`< ${locales.IndustryConsultation1}`}</div>
                <div className="contentView">
                    {
                        global.isPc &&
                        <div className="contentViewL">
                            <div className="contentViewLTitle">{locales.NewsContent1}</div>
                            <i className="line"></i>
                            <ul>
                                {
                                    servicLists.map((item, i) => {
                                        return (
                                            <li key={item + i} className={`servicLists ${i === index && 'servicListsIn'}`}>
                                                <div className="servicListsTime">{item.time}</div>
                                                <div className="servicListsContent">{item.content}</div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    }
                    <div className="contentViewR">
                        <div className="versionView">
                            <h3 className="versionTitle">{servicLists[index].content}</h3>
                            <div className="versionTime">{servicLists[index].time}</div>
                            <div className="versionLine"></div>
                            <div className="versionPic">
                                <img
                                    src={
                                        index +1 === 1 ?
                                            servicListsPic1 : index+1  === 2 ?
                                                servicListsPic2 : index+1  === 3 ?
                                                    servicListsPic3 : index+1  === 4 ?
                                                        servicListsPic4 : index +1  === 5 ?
                                                            servicListsPic5 : index +1  === 6 ?
                                                                servicListsPic6 : ''
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="versionContent">
                                <TextArea
                                    bordered={false}
                                    value={versionLists[index].content}
                                    autoSize
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer section />
        </div>
    )
}