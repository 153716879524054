import React, { useContext } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import { tampCreateContext } from '@/utils/context';
import './Service.scss';

export default function Service(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;

    function _LeadWay() {
        props.history.push('/Know/');
    }
    function _CorporateCulture() {
        props.history.push('/Join/CorporateCulture');
    }

    return (
        <div className="Service">
            <HeadMenu Background={1} />

            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                    // setindex(destination.index);
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className="ServiceCulture section ServiceCulture1">
                                <div className={`tampInfos ${!global.isPc && 'md_tampInfos3'}`}>
                                    <div className="tampInfosViePic"></div>
                                    <div className="tampInfosViewText">
                                        <div className="tampInfosViewTextBox">
                                            <div className="tampInfosBoxTiele">{locales.Service1}</div>
                                            <div className="tampInfosBoxText">
                                                {locales.Service2}
                                            </div>
                                            <div className="tampInfosBoxText">

                                            </div>
                                            <div className="tampInfosBoxText1" onClick={_LeadWay}>
                                                {locales.Service3} >
                                        </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="ServiceCulture section">
                                <div className={`tampInfos ${!global.isPc && 'md_tampInfos3'}`}>
                                    <div className="tampInfosViePic"></div>
                                    <div className="tampInfosViewText">
                                        <div className="tampInfosViewTextBox">
                                            <div className="tampInfosBoxTiele">{locales.Service4}</div>
                                            <div className="tampInfosBoxText">
                                                {locales.Service5}
                                            </div>
                                            <div className="tampInfosBoxText">
                                                {locales.Service6}
                                            </div>
                                            <div className="tampInfosBoxText" onClick={_CorporateCulture}>
                                                {locales.Service7} >
                                        </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <Footer section />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}