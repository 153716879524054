import React, { useContext } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { tampCreateContext } from '@/utils/context';
import { servicLists } from '@/utils/versions';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper-bundle.min.css';
import './IndustryConsultation.scss';

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

export default function IndustryConsultation(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;

    function _NewsContent(data) {
        // props.history.push('/News/NewsContent/' + data);
        props.history.push({
            pathname: '/News/NewsContent/',
            state: { name: data -1}
        });
    }

    return (
        <div className="IndustryConsultation">
            <HeadMenu Background={1} />

            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                    // setindex(destination.index);
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className={`content1 section `}>
                                <div className={`content1View ${!global.isPc && 'md_content1'}`}>
                                    <div className="ServiceCulture">
                                        <div className={`tampInfos ${!global.isPc && 'md_tampInfos3'}`}>
                                            <div className="tampInfosViePic"></div>
                                            <div className="tampInfosViewText">
                                                <div className="tampInfosViewTextBox">
                                                    <div className="tampInfosBoxTiele">张悦欣：美国全托资管平台TAMP进入4.0时代——中国市场潜力较大 | 国际</div>
                                                    <div className="tampInfosBoxText">
                                                        2020-02-25
                                                    </div>
                                                    <div className="tampInfosBoxText">
                                                    美国市场现状与展望:由公募基金组合账户至联合管理家庭账户，账户的客群收入递增，投资产品维度越发复杂，TAMP带来的相应产品的投资效率也提升地越高。

                                                    </div>
                                                    <div className="tampInfosBoxText" onClick={() => _NewsContent(2)}>
                                                        {locales.CompanyNews1} >
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content content2 section">
                                <div className="content2View" style={!global.isPc ? { height: '100vh' } : {}}>
                                    <div className="contentTitle">{locales.IndustryConsultation1}</div>
                                    {
                                        global.isPc ?
                                            <div className="dynamicView">
                                                <ul>
                                                    {
                                                        [servicLists[0], servicLists[1], servicLists[2]].map((item, index) => {
                                                            return (
                                                                <li
                                                                    key={item + index}
                                                                    onClick={() => _NewsContent(item.id)}
                                                                >
                                                                    <div className="dynamiPic"></div>
                                                                    <div className="dynamiTime">{item.time}</div>
                                                                    <div className="dynamiTitle">{item.content}</div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <ul>
                                                    {
                                                        [servicLists[3], servicLists[4], servicLists[5]].map((item, index) => {
                                                            return (
                                                                <li
                                                                    key={item + index}
                                                                    onClick={() => _NewsContent(item.id)}
                                                                >
                                                                    <div className="dynamiPic"></div>
                                                                    <div className="dynamiTime">{item.time}</div>
                                                                    <div className="dynamiTitle">{item.content}</div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            :
                                            <Swiper
                                                spaceBetween={0}
                                                slidesPerView={1.2}
                                                pagination={{ clickable: true }}
                                            >
                                                <div className="swiper-pagination"></div>
                                                {
                                                    servicLists.map((item, index) => {
                                                        return (
                                                            <SwiperSlide
                                                                key={item + index}
                                                                onClick={() => _NewsContent(item.id)}
                                                            >

                                                                <div className="md_dynamicView">
                                                                    <div className={`md_dynamiPic md_dynamiPic${item.id - 1}`}></div>
                                                                    <div className="md_dynamiTime">{item.time}</div>
                                                                    <div className="md_dynamiTitle">{item.content}</div>
                                                                </div>
                                                            </SwiperSlide>
                                                        )
                                                    })
                                                }
                                                {/* <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <a href="https://mp.weixin.qq.com/s/wN0G-81jBHri_MznnXuHpA" target="_Blank" rel="noreferrer">
                                                            <div className="md_dynamiPic md_dynamiPic1"></div>
                                                            <div className="md_dynamiTime">2020-09-18</div>
                                                            <div className="md_dynamiTitle">挑战赛、签约入驻、招聘会｜一量科技携手甬舟大发展</div>
                                                        </a>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <a href="https://mp.weixin.qq.com/s/RY308vJ8mV2BfJzboOkv-Q" target="_Blank" rel="noreferrer">
                                                            <div className="md_dynamiPic md_dynamiPic2"></div>
                                                            <div className="md_dynamiTime">2020-09-24</div>
                                                            <div className="md_dynamiTitle">保税区金融科技产业园入驻企业一量科技荣获“创新在宁波”电梯挑战赛第二名</div>
                                                        </a>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <a href="https://mp.weixin.qq.com/s/HTZRrv5jBah9FHHgfMXDpg" target="_Blank" rel="noreferrer">
                                                            <div className="md_dynamiPic md_dynamiPic3"></div>
                                                            <div className="md_dynamiTime">2020-08-21</div>
                                                            <div className="md_dynamiTitle">“一量”新科技，创新来演绎。</div>
                                                        </a>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <a href="https://mp.weixin.qq.com/s/qHHPIXgdqCX--0jNWtaXaA" target="_Blank" rel="noreferrer">
                                                            <div className="md_dynamiPic md_dynamiPic4"></div>
                                                            <div className="md_dynamiTime">2020-12-01</div>
                                                            <div className="md_dynamiTitle">正青春，耀未来|宁波理工大学校园招聘会</div>
                                                        </a>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <a href="https://mp.weixin.qq.com/s/YNNCRDAc4mtEhiVJre3Kng" target="_Blank" rel="noreferrer">
                                                            <div className="md_dynamiPic md_dynamiPic5"></div>
                                                            <div className="md_dynamiTime">2020-11-06</div>
                                                            <div className="md_dynamiTitle">共同进步，共同成长|与通商银行开展现场交流</div>
                                                        </a>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="md_dynamicView">
                                                        <a href="https://mp.weixin.qq.com/s/MMFmGUQqzatwe_Kc5ZxhAA" target="_Blank" rel="noreferrer">
                                                            <div className="md_dynamiPic md_dynamiPic6"></div>
                                                            <div className="md_dynamiTime">2020-09-11</div>
                                                            <div className="md_dynamiTitle">“职”在未来|一量科技9.18宁波高端人才线下洽谈会等你来</div>
                                                        </a>
                                                    </div>
                                                </SwiperSlide> */}
                                            </Swiper>
                                    }
                                </div>

                            </div>
                            <Footer section />

                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}