import React, { createContext, useReducer } from 'react';
export const tampCreateContext = createContext('')

const data = {
    language: sessionStorage.getItem('language'),
    locales: sessionStorage.getItem('language') === 'en' ? require('@/locales/en-US.json') : require('@/locales/zh-CN.json')
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'cn':
            sessionStorage.setItem('language', 'cn');
            return { ...state, language: 'cn', locales: require('@/locales/zh-CN.json') };
        case 'en':
            sessionStorage.setItem('language', 'en');
            return { ...state, language: 'en', locales: require('@/locales/en-US.json') };
        default:
            return state;
    }
}

export const TampContext = props => {
    const [store, dispatch] = useReducer(reducer, data);
    return (
        <tampCreateContext.Provider value={{ store, dispatch }}>
            {props.children}
        </tampCreateContext.Provider>
    )
}
