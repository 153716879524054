import React, { useContext } from 'react';
import HeadMenu from '@/component/HeadMenu/HeadMenu';
import Footer from '@/component/Footer/Footer';
import ReactFullpage from '@fullpage/react-fullpage';
import SwiperCore, { Navigation, Scrollbar, Controller } from 'swiper';
import { tampCreateContext } from '@/utils/context';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper-bundle.min.css';
import './HonorQualification.scss';
import qualifications1 from '../KonwImages/qualifications1.jpeg';
import qualifications2 from '../KonwImages/qualifications2.jpeg';
import qualifications3 from '../KonwImages/qualifications3.jpeg';
import qualifications4 from '../KonwImages/qualifications4.jpeg';
import qualifications5 from '../KonwImages/qualifications5.jpg';
import qualifications6 from '../KonwImages/qualifications6.jpeg';
import qualificationsPic1 from '../KonwImages/qualificationsPic1.jpeg';
import qualificationsPic3 from '../KonwImages/qualificationsPic3.png';
import qualificationsPic4 from '../KonwImages/qualificationsPic4.jpeg';

SwiperCore.use([Navigation, Scrollbar, Controller]);

export default function HonorQualification(props) {
    const { store } = useContext(tampCreateContext);
    const locales = store.locales;

    return (
        <div className="HonorQualification">
            <HeadMenu Background={1} />

            <ReactFullpage
                licenseKey={'YOUR_KEY_HERE'}
                scrollingSpeed={600}
                scrollOverflow={true}
                onLeave={function (origin, destination, direction) {
                    // setindex(destination.index);
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className="content1 section">
                                <div className={`content1View ${!global.isPc && 'md_content1View'}`} >
                                    <div className="aboutUs">
                                        <div className="aboutUsPic">
                                            <img src={qualifications6} alt="" />
                                        </div>
                                        <div className="aboutUsContent">
                                            <div className="aboutUsContentView">
                                                <div className="aboutUsContentTitle">{locales.HonorQualification1}</div>
                                                <div className="aboutUsContentText">
                                                    {locales.HonorQualification2}
                                                </div>
                                                <div className="aboutUsContentText aboutUsContentTextCle">
                                                    {locales.HonorQualification3}
                                                </div>
                                                {/* <div className="aboutUsContentText">
                                                    {locales.HonorQualification4}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content2 section">
                                <div className="content2View">
                                    <div className="content2Viewtit">{locales.HonorQualification5}</div>
                                    <Swiper
                                        // spaceBetween={0}
                                        initialSlide={1}   // 默认第几条
                                        slidesPerView={global.isPc ? 3 : 1.3}
                                        centeredSlides={!global.isPc}  // 居中
                                        className={`content2ViewSwipers`}
                                    >
                                        <SwiperSlide>
                                            <div className="content2ViewSwipersView">
                                                <img src={qualificationsPic1} alt="" />
                                                <div>{locales.HonorQualification6}</div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="content2ViewSwipersView">
                                                <img src={qualificationsPic3} alt="" />
                                                <div>{locales.HonorQualification7}</div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="content2ViewSwipersView">
                                                <img src={qualificationsPic4} alt="" />
                                                <div>{locales.HonorQualification8}</div>
                                            </div>
                                        </SwiperSlide>

                                    </Swiper>
                                </div>
                            </div>

                            <div className="honosView section">
                                <div className="honosConView">
                                    <div className="content2Viewtit">{locales.HonorQualification9}</div>
                                    <div className="honos">
                                        <Swiper
                                            spaceBetween={0}
                                            initialSlide={1}   // 默认第几条
                                            slidesPerView={global.isPc ? 3 : 1.5}
                                            centeredSlides={!global.isPc}  // 居中
                                            className={`honosSwiper`}
                                        >
                                            <SwiperSlide>
                                                <div className="honosSwiperView">
                                                    <div className="honoPic">
                                                        <img src={qualifications1} alt="" />
                                                    </div>
                                                    <div className="honosCon">{locales.HonorQualification10}</div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="honosSwiperView">
                                                    <div className="honoPic">
                                                        <img src={qualifications2} alt="" />
                                                    </div>
                                                    <div className="honosCon">{locales.HonorQualification11}</div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="honosSwiperView">
                                                    <div className="honoPic">
                                                        <img src={qualifications3} alt="" />
                                                    </div>
                                                    <div className="honosCon">{locales.HonorQualification12}</div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className="honos">
                                        <Swiper
                                            spaceBetween={20}
                                            initialSlide={1}   // 默认第几条
                                            slidesPerView={global.isPc ? 3 : 1.5}
                                            centeredSlides={!global.isPc}  // 居中
                                            className={`honosSwiper`}
                                        >
                                            <SwiperSlide>
                                                <div className="honosSwiperView">
                                                    <div className="honoPic">
                                                        <img src={qualifications4} alt="" />
                                                    </div>
                                                    <div className="honosCon">{locales.HonorQualification13}</div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="honosSwiperView">
                                                    <div className="honoPic">
                                                        <img src={qualifications5} alt="" />
                                                    </div>
                                                    <div className="honosCon">{locales.HonorQualification14}</div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="honosSwiperView">
                                                    <div className="honoPic">
                                                        <img src={qualifications6} alt="" />
                                                    </div>
                                                    <div className="honosCon">{locales.HonorQualification15}</div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>

                            <Footer section />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
}