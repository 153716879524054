import Home from '@/view/Homes/Home';
import TechnicalQuota from '@/view/Homes/TechnicalQuota/TechnicalQuota';
import Technology from '@/view/Homes/Technology/Technology';
import Service from '@/view/Homes/Service/Service';

import Join from '@/view/Joins/Join';
// import SalaryBenefits from '@/view/Joins/SalaryBenefits/SalaryBenefits';
import CorporateCulture from '@/view/Joins/CorporateCulture/CorporateCulture';
import Recruit from '@/view/Joins/Recruit/Recruit';

import Know from '@/view/Knows/Know';
// import CompanyProfile from '@/view/Knows/CompanyProfile/CompanyProfile';
import HonorQualification from '@/view/Knows/HonorQualification/HonorQualification';
// import CorporateProfile from '@/view/Knows/CorporateProfile/CorporateProfile';
import ContactUs from '@/view/Knows/ContactUs/ContactUs';

import News from '@/view/Newss/News';
import CompanyNews from '@/view/Newss/CompanyNews/CompanyNews';
import IndustryConsultation from '@/view/Newss/IndustryConsultation/IndustryConsultation';


import TAMP from '@/view/TAMPs/TAMP';

import Privacy from '@/view/Homes/Privacy/Privacy'
// import Investor from '@/view/TAMPs/Investor/Investor';
// import FundManager from '@/view/TAMPs/FundManager/FundManager';
// import MechanismPersonal from '@/view/TAMPs/MechanismPersonal/MechanismPersonal';


export const routeConfig_EN = [
    {
        path: "/",
        component: Home,
        name: 'Home',
        title: 'Home',
        childRoutes: [
            {
                path: "/Home/TechnicalQuota",
                name: 'TechnicalQuota',
                title: 'Investment amount',
                component: TechnicalQuota,
            },
            {
                path: "/Home/Technology",
                name: 'Technology',
                title: 'Technology',
                component: Technology,
            },
            {
                path: "/Home/Service",
                name: 'Service',
                title: 'Service',
                component: Service,
            },
            {
                path: "/Home/Privacy",
                name: 'Privacy',
                title: 'Privacy',
                component: Privacy,
            }
        ],
    },
    {
        path: "-",
        component: News,
        name: 'News',
        title: 'News information',
        childRoutes: [
            {
                path: "/News/CompanyNews",
                name: 'CompanyNews',
                title: 'FirstQuant dynamic',
                component: CompanyNews,
            },
            {
                path: "/News/IndustryConsultation",
                name: 'IndustryConsultation',
                title: 'Industry information',
                component: IndustryConsultation,
            }
        ],
    },
    {
        path: "/TAMP/",
        component: TAMP,
        name: 'TAMP',
        title: 'TAMP Platform services',
        childRoutes: [
            // {
            //     path: "/TAMP/Investor",
            //     name: 'Investor',
            //     title: '投资者',
            //     component: Investor,
            // },
            // {
            //     path: "/TAMP/FundManager",
            //     name: 'FundManager',
            //     title: '基金管理人',
            //     component: FundManager,
            // },
            // {
            //     path: "/TAMP/MechanismPersonal",
            //     name: 'MechanismPersonal',
            //     title: '机构/个人投资者',
            //     component: MechanismPersonal,
            // }
        ],
    },
    {
        path: "/Know/",
        name: 'Know',
        component: Know,
        title: 'About us',
        childRoutes: [
            // {
            //     path: "/Know/CompanyProfile",
            //     name: 'CompanyProfile',
            //     title: '公司简介',
            //     component: CompanyProfile,
            // },
            {
                path: "/Know/HonorQualification",
                name: 'HonorQualification',
                title: 'Honor and qualification',
                component: HonorQualification,
            },
            // {
            //     path: "/Know/CorporateProfile",
            //     name: 'CorporateProfile',
            //     title: '法人简介',
            //     component: CorporateProfile,
            // },
            {
                path: "/Know/ContactUs",
                name: 'ContactUs',
                title: 'Contact us',
                component: ContactUs,
            }
        ],
    },
    {
        path: "/Join/",
        name: 'Join',
        component: Join,
        title: 'Join us',
        childRoutes: [
            // {
            //     path: "/Join/SalaryBenefits",
            //     name: 'SalaryBenefits',
            //     title: '薪酬福利',
            //     component: SalaryBenefits,
            // },
            {
                path: "/Join/CorporateCulture",
                name: 'CorporateCulture',
                title: 'Corporate culture',
                component: CorporateCulture,
            },
            {
                path: "/Join/Recruit",
                name: 'Recruit',
                title: 'Recruit',
                component: Recruit,
            },
        ]
    },
]

export const routeConfig = [
    {
        path: "/",
        component: Home,
        name: 'Home',
        title: '首页',
        childRoutes: [
            {
                path: "/Home/TechnicalQuota",
                name: 'TechnicalQuota',
                title: '解决方案',
                component: TechnicalQuota,
            },
            {
                path: "/Home/Technology",
                name: 'Technology',
                title: '技术',
                component: Technology,
            },
            {
                path: "/Home/Service",
                name: 'Service',
                title: '服务',
                component: Service,
            },
            {
                path: "/Home/Privacy",
                name: 'Privacy',
                title: '隐私政策',
                component: Privacy,
            }
        ],
    },
    {
        path: "-",
        component: News,
        name: 'News',
        title: '新闻动态',
        childRoutes: [
            {
                path: "/News/CompanyNews",
                name: 'CompanyNews',
                title: '一量动态',
                component: CompanyNews,
            },
            {
                path: "/News/IndustryConsultation",
                name: 'IndustryConsultation',
                title: '行业资讯',
                component: IndustryConsultation,
            }
        ],
    },
    {
        path: "/TAMP/",
        component: TAMP,
        name: 'TAMP',
        title: 'TAMP平台服务',
        childRoutes: [
            // {
            //     path: "/TAMP/Investor",
            //     name: 'Investor',
            //     title: '投资者',
            //     component: Investor,
            // },
            // {
            //     path: "/TAMP/FundManager",
            //     name: 'FundManager',
            //     title: '基金管理人',
            //     component: FundManager,
            // },
            // {
            //     path: "/TAMP/MechanismPersonal",
            //     name: 'MechanismPersonal',
            //     title: '机构/个人投资者',
            //     component: MechanismPersonal,
            // }
        ],
    },
    {
        path: "/Know/",
        name: 'Know',
        component: Know,
        title: '关于我们',
        childRoutes: [
            // {
            //     path: "/Know/CompanyProfile",
            //     name: 'CompanyProfile',
            //     title: '公司简介',
            //     component: CompanyProfile,
            // },
            {
                path: "/Know/HonorQualification",
                name: 'HonorQualification',
                title: '荣誉资质',
                component: HonorQualification,
            },
            // {
            //     path: "/Know/CorporateProfile",
            //     name: 'CorporateProfile',
            //     title: '法人简介',
            //     component: CorporateProfile,
            // },
            {
                path: "/Know/ContactUs",
                name: 'ContactUs',
                title: '联系我们',
                component: ContactUs,
            }
        ],
    },
    {
        path: "/Join/",
        name: 'Join',
        component: Join,
        title: '加入我们',
        childRoutes: [
            // {
            //     path: "/Join/SalaryBenefits",
            //     name: 'SalaryBenefits',
            //     title: '薪酬福利',
            //     component: SalaryBenefits,
            // },
            {
                path: "/Join/CorporateCulture",
                name: 'CorporateCulture',
                title: '企业文化',
                component: CorporateCulture,
            },
            {
                path: "/Join/Recruit",
                name: 'Recruit',
                title: '招贤纳士',
                component: Recruit,
            },
        ]
    },
]

