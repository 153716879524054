import React, { useState, useEffect, useContext } from 'react';
import { Popover } from 'antd';
import { routeConfig, routeConfig_EN } from '@/router/route.config';
import { withRouter, Link, useLocation } from 'react-router-dom';
import { tampCreateContext } from '@/utils/context';
import QRCode from 'qrcode.react';
import './HeadMenu.scss';
import _logoCN from "@/images/_logoCN.svg";
import logoCN from "@/images/logoCN.svg";
import _logoEN from "@/images/_logoEN.svg";
import logoEN from "@/images/logoEN.svg";
import phoneIcon from '@/images/phone.png'

function HeadMenu(props) {
    const { store, dispatch } = useContext(tampCreateContext);
    const locales = store.locales;
    const { pathname } = useLocation();
    const [isPc, setisPc] = useState(true);
    const [menuIcon, setmenuIcon] = useState(null);
    const [menusChild, setmenusChild] = useState({});
    const [routeConfigData, setrouteConfigData] = useState(store.language === 'en' ? routeConfig_EN : routeConfig);
    const [language, setlanguage] = useState(false);


    useEffect(() => {
        isPC();
        // console.log(6666, store);
    }, [pathname, store])

    function isPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone",
            "SymbianOS", "Windows Phone",
            "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return setisPc(flag);
    }
    function handleClick(e, path) {
        if (e.target.nodeName.toLowerCase() === 'p') {
            return;
        }
        console.log(path);
        if (path !== '-') {
            props.history.push(path);
        }
    }
    function _menuListChild(e, path) {
        console.log(path);
        props.history.push(path);
    }
    function _mdMenuIcon() {
        setmenuIcon(!menuIcon);
    }
    function _mdHandleClick(path, index) {
        // console.log(path);
        if (menusChild[index]) {
            setmenusChild({ ...menusChild, [index]: null });
        } else {
            setmenusChild({ ...menusChild, [index]: path });
        }
    }
    function _mdHandleClicList(path) {
        props.history.push(path);
    }
    function _pathName(item) {
        let pathnameSp = `/${pathname.split('/')[1]}/`;
        if (pathnameSp === '/Home/') {
            pathnameSp = '/';
        } else if (pathnameSp === '/News/') {
            pathnameSp = '-';
        }
        if (pathname === item.path || pathnameSp === item.path) {
            return 'pathnameIn'
        }
        return "";
    }
    function _languageSwitch(data) {
        dispatch({ type: data });
        setrouteConfigData(data === 'cn' ? routeConfig : routeConfig_EN);
    }
    function _mdLanguageSwitch(data) {
        dispatch({ type: data });
        setrouteConfigData(data === 'cn' ? routeConfig : routeConfig_EN);
        setlanguage(false);
    }
    function _language() {
        setlanguage(!language);
    }

    const popContent = () => {
        return <div>
            <QRCode value={'https://cfirstquant.com/download/downloadApp.html'} />
        </div>
    }

    return (
        <>
            {
                isPc ?
                    <div className={`HeadMenu ${props.Background && 'Background'}`} >
                        <h1>
                            <Link to="/">
                                {props.Background && <img src={store.language === 'en' ? _logoEN : _logoCN} className="logo" alt=""></img>}
                                {!props.Background && <img src={store.language === 'en' ? logoEN : logoCN} className="logo" alt=""></img>}
                            </Link>
                        </h1>
                        <div className="menu">
                            <ul>
                                {
                                    routeConfigData.map((item, index) => {
                                        return (
                                            <li
                                                key={item + index}
                                                onClick={(e) => handleClick(e, item.path)}
                                                className={_pathName(item)}
                                            >
                                                {item.title}
                                                <div className="icon"></div>
                                                <div className={`${item.childRoutes.length !== 0 ? 'menuListChild' : 'menuListChild1'}`}>
                                                    <div className="menuListChildView">
                                                        {
                                                            item.childRoutes.map((itemChild, indexChild) => {
                                                                return (
                                                                    <p
                                                                        key={itemChild + indexChild}
                                                                        className={`menuListChildViewList ${pathname === itemChild.path && 'pathnameChildIn'}`}
                                                                        onClick={(e) => _menuListChild(e, itemChild.path)}
                                                                    >
                                                                        {itemChild.title}
                                                                    </p>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>

                        <div className="HeaderRight">
                            <div>{locales.HeadMenu1}</div>
                            <div className="choice">
                                <a href="https://simx.cfirstquant.com/" target="_Blank" rel="noreferrer">{locales.HeadMenu2}</a>
                            </div>
                            <div className="choiceHide">
                                <div className="choiceHideView">
                                    <div><a href="https://simx.cfirstquant.com/" target="_Blank" rel="noreferrer">{locales.HeadMenu3}</a></div>
                                    <div><a href="https://simx.cfirstquant.com/" target="_Blank" rel="noreferrer">{locales.HeadMenu4}</a></div>
                                </div>
                            </div>
                        </div>

                        <div>{// className="language"
                        }
                            {/* <i className={`languageLogo ${props.Background && '_languageLogo'}`}></i>
                            <div className="languageTitle">{store.language === 'en' ? 'English' : '简体中文'}</div>
                            <div className="choiceHide">
                                <div className="choiceHideView">
                                    <div onClick={() => _languageSwitch('cn')} >简体中文</div>
                                    <div onClick={() => _languageSwitch('en')}>English</div>
                                </div>
                            </div> */}
                            服务热线:400-966-8916
                        </div>

                        <div>
                            <Popover content={popContent} title={null}>
                                <div style={{}}>
                                    <img src={phoneIcon} style={{ width: '11px', marginRight: '6px', marginTop: '-3px' }} />
                                    <span>一量云助手APP</span>
                                </div>
                            </Popover>
                        </div>
                    </div>
                    :
                    <div className={`md_HeadMenu ${language ? 'md_HeadMenuLanguage' : 'md_HeadMenuLanguageNo'}`}>
                        <img src={_logoCN} className="md_logo" alt=""></img>
                        <div
                            className={`inner-header-icon ${menuIcon ? 'inner-header-icon-click' : 'inner-header-icon-out'}`}
                            onClick={_mdMenuIcon}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        <div className={`md_language ${language && 'md_languageIn'}`} onClick={_language}>
                            <i className={`md_languageLogo`}></i>
                            <div className="md_languageTitle">{store.language === 'en' ? 'English' : '简体中文'}</div>
                        </div>

                        <div className="md_languageHide">
                            <div className="md_languageHideDelView">
                                <div className="md_languageHideTitle">选择语言</div>
                                <div className="md_languageHideDel" onClick={() => setlanguage(false)}>X</div>
                            </div>
                            <div
                                className={`md_languageHideCon ${store.language !== 'en' && 'md_languageHideConIn'}`}
                                onClick={() => _mdLanguageSwitch('cn')}
                            >
                                简体中文
                            </div>
                            <div
                                className={`md_languageHideCon ${store.language === 'en' && 'md_languageHideConIn'}`}
                                onClick={() => _mdLanguageSwitch('en')}
                            >
                                English
                            </div>
                        </div>

                        {
                            menuIcon !== null &&
                            <div className={`md_menuList ${menuIcon ? 'animated fadeInDown' : 'animated fadeOutUp'}`}>
                                <div className="md_menuLisUl">
                                    {
                                        routeConfigData.map((item, index) => {
                                            return (
                                                <div key={item + index} className={`${menusChild[index] && 'md_menuLisLiOpen'}`} >
                                                    <div
                                                        className={`md_menuLisLi`}
                                                        onClick={() => _mdHandleClick(item.path, index)}
                                                    >
                                                        {item.title}
                                                        {
                                                            [item.path !== '-' && { title: item.title, path: item.path }, ...item.childRoutes].map((childItem, childIndex) => {
                                                                return (
                                                                    <div
                                                                        className={`md_menuLisLiItem`}
                                                                        key={childItem + childIndex}
                                                                        onClick={() => _mdHandleClicList(childItem.path)}
                                                                    >
                                                                        {childItem.title}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>

            }
        </>
    )
}

export default withRouter(HeadMenu);