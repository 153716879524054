import React, { useEffect } from 'react';
import Guards from '@/router/Guards';
import 'antd/dist/antd.css';
import { TampContext } from '@/utils/context';
import './index.scss';
// import './mdIndex.css';

function App() {

  useEffect(() => {
    isPC();
  }, [])

  function isPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    if (!flag) {
      var link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = './mdIndex.css';
      console.log(1111);
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    return global.isPc = flag;
  }

  return (
    // <div className={`${ !isPC() && 'mdScss' }`}>
    <div>
      <TampContext>
        <Guards />
      </TampContext>
    </div>
  );
}

export default App;
